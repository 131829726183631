import React, {PureComponent} from 'react'

class AuthNotification extends PureComponent {
  render() {
    return (
      <div style={{height: '1em'}} {...this.props}>
        {this.props.children}
      </div>
    )
  }
}

export default AuthNotification
