import React, {Component} from 'react'
import mascNormal from '../../assets/mascotte_normal.png'
import DisplayTag from '../../shared/components/displayTag'
import {Mascotte, IdeaContainer, IdeaHeader, IdeaText, TitleSorter, IdeaFooter, IdeaFooterText, IdeaDescriptionLarge, IdeaActionGrouper} from '../style/style'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import CommentContainer from './commentContainer'
import ReactTimeAgo from 'react-time-ago'
import styled from 'styled-components'
import CommentCount from '../listing/commentCount'
import HeartButton from '../../shared/components/heartButton'
import {inject, observer} from 'mobx-react'
import gql from 'graphql-tag'
import {Query} from 'react-apollo'
import {Redirect} from 'react-router-dom'
import AcceptButton from '../../shared/components/acceptButton'
import ArchiveButton from '../../shared/components/archiveButton'

const IDEA_QUERY = gql`
  query IdeaQuery($id: String!) {
      idea(id: $id) {
        id
        title
        description
        createdAt
        accepted
        archived
        likedIdeas {
          id
          firstName
          lastName
        }
        user {
          id
          firstName
          lastName
        }
        tags {
          id
          text
        }
      }
    }
`

const IDEA_LIKE_CHANGED_SUBSCRIPTION = gql`
  subscription {
    ideaLikeChanged {
      id
      title
      description
      createdAt
      likedIdeas {
        id
        firstName
        lastName
      }
      user {
        id
        firstName
        lastName
      }
      tags {
        id
        text
      }
    }
  }
`

const IDEA_STATE_CHANGED_SUBSCRIPTION = gql`
  subscription {
    ideaStateChanged {
      id
      title
      description
      createdAt
      accepted
      archived
      likedIdeas {
        id
        firstName
        lastName
      }
      user {
        id
        firstName
        lastName
      }
      tags {
        id
        text
      }
    }
  }
`

const Title = styled.h4`
  color: ${props => props.theme.palette.grey};
`

const BackButton = styled(FontAwesomeIcon)`
  color: props.theme.palette.dark_grey;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.palette.light_grey};
  }
`

@inject('authStore')
@observer
class ViewIdea extends Component {
  subscribeToNewLikes = subscribeToMore => {
    subscribeToMore({
      document: IDEA_LIKE_CHANGED_SUBSCRIPTION
    })
  }

  subscribeToStateChanges = subscribeToMore => {
    subscribeToMore({
      document: IDEA_STATE_CHANGED_SUBSCRIPTION,
    })
  }

  render() {
    const convertDateToString = (dateString) => {
      const date = new Date(dateString)
      return date
    }

    const hasCurrentUserLikedIdea = (idea) => {
      return (idea.likedIdeas.filter(user => (user.id === this.props.authStore.id))).length > 0
    }

    const goBack = () => {
      this.props.history.push('/')
    }

    const {id} = this.props.match.params
    if (!id) {
      return (
        <Redirect to={{pathname: '/'}} />
      )
    } else {
      return (
        <React.Fragment>
            <Mascotte src={mascNormal} />
            <IdeaContainer>
              <Query query={IDEA_QUERY} variables={{ id: id }}>
                {({ loading, error, data, subscribeToMore }) => {
                  if (loading) return "Laden...";
                  if (error) return `Error! ${error.message}`;
                  const idea = data.idea;

                  this.subscribeToNewLikes(subscribeToMore)
                  this.subscribeToStateChanges(subscribeToMore)

                  return (
                    <React.Fragment>
                      <IdeaHeader>
                        <TitleSorter>
                          <IdeaText><BackButton icon="arrow-left" onClick={goBack} /> <strong>{idea.title}</strong></IdeaText>
                          {idea.accepted &&
                            <IdeaText accepted><small><FontAwesomeIcon icon="check-circle" />&nbsp; geaccepteerd</small></IdeaText>
                          }
                          {idea.archived &&
                            <IdeaText archived><small><FontAwesomeIcon icon="times-circle" />&nbsp; gearchiveerd</small></IdeaText>
                          }
                        </TitleSorter>
                        <IdeaText grey><small><ReactTimeAgo date={convertDateToString(idea.createdAt)} locale="nl"/></small></IdeaText>
                      </IdeaHeader>
                      <div>
                        {idea.tags.map(tag => (
                          <DisplayTag key={tag.id}>{tag.text}</DisplayTag>
                        ))}
                      </div>
                      <IdeaDescriptionLarge dangerouslySetInnerHTML={{__html: idea.description}} />
                      <IdeaFooter>
                        <div>
                          <IdeaText grey>&bull; {idea.user.firstName} {idea.user.lastName}</IdeaText>
                        </div>
                        <IdeaActionGrouper>
                          {this.props.authStore.admin &&
                            <React.Fragment>
                              <IdeaFooterText data-testid={'idea_details_admin_accept_button'}>
                                <AcceptButton selected={idea.accepted} canSelect={!idea.archived} ideaId={idea.id}/>
                              </IdeaFooterText>
                              <IdeaFooterText data-testid={'idea_details_admin_archive_button'}>
                                <ArchiveButton selected={idea.archived} canSelect={!idea.accepted} ideaId={idea.id}/>
                              </IdeaFooterText>
                            </React.Fragment>
                          }
                          <IdeaFooterText data-testid={'idea_details_like_button'}>
                            <HeartButton selected={hasCurrentUserLikedIdea(idea)} ideaId={idea.id} likes={idea.likedIdeas.length} />
                            </IdeaFooterText>
                          <IdeaFooterText>
                            <FontAwesomeIcon icon="comment" />
                            {' '}
                            <CommentCount ideaId={idea.id} />
                          </IdeaFooterText>
                        </IdeaActionGrouper>
                      </IdeaFooter>
                    </React.Fragment>
                  );
                }}
              </Query>
            </IdeaContainer>
            <Title id={'comments'}>Discussie</Title>
            <CommentContainer ideaId={id} />
        </React.Fragment>
      )
    } 
  }
}

export default ViewIdea
