import React, {Component} from 'react'
import mascNormal from '../../assets/mascotte_normal.png'
import {Mascotte} from '../style/style'
import styled from 'styled-components'
import {inject, observer} from 'mobx-react'
import gql from 'graphql-tag'
import {Query} from 'react-apollo'
import UserRow from './userRow';

const ALL_USERS_QUERY = gql`
  {
    allUsers {
      id
      firstName
      lastName
      email
      admin
      enabled
      emailVerified
      createdAt
    }
  }
`

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`

class UserOverview extends Component {

  render() {
    return (
        <React.Fragment>
            <Mascotte src={mascNormal} />
            <Query query={ALL_USERS_QUERY}>
              {({loading, error, data}) => {
                if (loading) return <div>Gebruikers laden...</div>
                if (error) return <div>Kon gebruikers niet laden :(</div>

                const allUsers = data.allUsers

                return (
                  <StyledTable>
                    <tr>
                      <th align="left">Naam en e-mail</th>
                      <th align="left">Administrator</th>
                      <th align="left">Geverifieerd</th>
                      <th align="left">Ingeschakeld</th>
                    </tr>
                    {allUsers.map(user => (
                      <UserRow key={user.id} user={user} />
                    ))}
                  </StyledTable>
                )
              }}
            </Query>
        </React.Fragment>
    )
  }
}

export default UserOverview
