import React, {Component} from 'react'
import styled from 'styled-components'
import {inject, observer} from 'mobx-react'
import gql from 'graphql-tag'
import Checkbox from '../../shared/components/checkbox'
import {ApolloConsumer} from 'react-apollo'
import Spinner from '../../shared/components/spinner'

const ENABLE_OR_DISABLE_USER_MUTATION = gql`
  mutation EnableOrDisableUserMutation($userId: String!) {
    enableOrDisableUser(userId: $userId)
  }
`

const MAKE_USER_ADMIN_MUTATION = gql`
  mutation MakeUserAdminMutation($userId: String!) {
    makeUserAdmin(userId: $userId)
  }
`

const VERIFY_EMAIL_FOR_ADMIN_MUTATION = gql`
  mutation VerifyEmailForAdminMutation($userId: String!) {
    verifyEmailForAdmin(userId: $userId)
  }
`

const StyledCell = styled.td`
  border-bottom: 1px solid ${props => props.theme.palette.light_grey};
`

@inject('authStore')
@observer
class UserRow extends Component {
  state = {
    admin: this.props.user.admin,
    adminLoading: false,
    emailVerified: this.props.user.emailVerified,
    emailVerifiedLoading: false,
    enabled: this.props.user.enabled,
    enabledLoading: false
  }

  render() {
    const user = this.props.user

    const handleClickAdmin = async(client) => {
      this.setState(() => ({
        admin: !this.state.admin,
        adminLoading: true
      }))

      const {data} = await client.mutate({
        mutation: MAKE_USER_ADMIN_MUTATION,
        variables: { userId: this.props.user.id }
      })

      this.setState(() => ({
        admin: data.makeUserAdmin,
        adminLoading: false
      }))
    }

    const handleClickEmailVerified = async(client) => {
      this.setState(() => ({
        emailVerified: !this.state.emailVerified,
        emailVerifiedLoading: true
      }))

      const {data} = await client.mutate({
        mutation: VERIFY_EMAIL_FOR_ADMIN_MUTATION,
        variables: { userId: this.props.user.id }
      })

      this.setState(() => ({
        emailVerified: data.verifyEmailForAdmin,
        emailVerifiedLoading: false
      }))
    }

    const handleClickEnabled = async(client) => {
      this.setState(() => ({
        enabled: !this.state.enabled,
        enabledLoading: true
      }))

      const {data} = await client.mutate({
        mutation: ENABLE_OR_DISABLE_USER_MUTATION,
        variables: { userId: this.props.user.id }
      }) 
      
      this.setState(() => ({
        enabled: data.enableOrDisableUser,
        enabledLoading: false
      }))
    }

    return (
        <React.Fragment>
          <tr>
            <StyledCell>
              <p>{user.firstName} {user.lastName} ({user.email})</p>
            </StyledCell>
            <StyledCell>
              <ApolloConsumer>
                {client => (
                  <label>
                    <Checkbox
                      checked={this.state.admin}
                      onChange={() => handleClickAdmin(client)}
                      data-testid={'admin_users_admin_button'}
                    />
                    {this.state.adminLoading &&
                      <span>&nbsp;<Spinner/></span>
                    }
                  </label>
                )}
              </ApolloConsumer>
            </StyledCell>
            <StyledCell>
              <ApolloConsumer>
                  {client => (
                    <label>
                      <Checkbox
                        checked={this.state.emailVerified}
                        onChange={() => handleClickEmailVerified(client)}
                        data-testid={'admin_users_email_button'}
                      />
                      {this.state.emailVerifiedLoading &&
                        <span>&nbsp;<Spinner/></span>
                      }
                    </label>
                  )}
                </ApolloConsumer>
            </StyledCell>
            <StyledCell>
              <ApolloConsumer>
                {client => (
                  <label>
                    <Checkbox
                      checked={this.state.enabled}
                      onChange={() => handleClickEnabled(client)}
                      data-testid={'admin_users_enable_button'}
                    />
                    {this.state.enabledLoading &&
                      <span>&nbsp;<Spinner/></span>
                    }
                  </label>
                )}
              </ApolloConsumer>
            </StyledCell>
          </tr>
        </React.Fragment>
    )
  }
}

export default UserRow
