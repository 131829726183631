import React from 'react'
import {Mutation} from 'react-apollo'
import gql from 'graphql-tag'

const VERIFY_MUTATION = gql`
  mutation VerifyMutation($id: String!, $token: String!) {
    verify(id: $id, token: $token) {
      email
    }
  }
`

class CallVerify extends React.Component {
  componentDidMount() {
    this.props.verify()
  }

  render() {
    return this.props.children
  }
}

export default function Verifier({id, token, render}) {
  return (
    <Mutation mutation={VERIFY_MUTATION} variables={{id, token}}>
      {(verify, {data, error}) => {
        return <CallVerify verify={verify}>{render({error, data})}</CallVerify>
      }}
    </Mutation>
  )
}
