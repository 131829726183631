import React, {Component} from 'react'
import PostIdea from './posting/postIdea'
import Login from './authentication/login'
import Registration from './authentication/registration'
import Header from '../shared/components/header'
import { Switch, Route, Redirect } from 'react-router-dom'
import styled, {createGlobalStyle, ThemeProvider} from 'styled-components'
import {theme} from '../shared/theme'
import PrivateRoute from '../components/authentication/privateRoute'
import authStore from '../shared/stores/authStore'
import ideaStore from '../shared/stores/ideaStore'
import Home from './home'
import {Provider} from 'mobx-react'
import Verification from './authentication/verification'
import ViewIdea from './details/viewIdea'
import SendPasswordResetMail from './authentication/sendPasswordResetMail'
import ResetPassword from './authentication/resetPassword'
import ResetPasswordWithoutToken from './authentication/resetPasswordWithoutToken';
import {library} from '@fortawesome/fontawesome-svg-core'
import {faHeart, faComment, faUserCircle, faSignOutAlt, faKey, faChevronDown, faCheck, faArchive, faCheckCircle, faTimesCircle, faUsers, faTimes, faThumbsUp, faSadCry, faFireAlt, faClock, faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import UserOverview from './admin/userOverview'
import {loginCheck} from '../shared/util/loginCheck'
import Message from '../shared/components/message'
//import './app.css'

library.add(faHeart, faComment, faUserCircle, faSignOutAlt, faKey, faChevronDown, faCheck, faArchive, faCheckCircle, faTimesCircle, faUsers, faTimes, faThumbsUp, faSadCry, faFireAlt, faClock, faArrowLeft)

const GlobalStyle = createGlobalStyle`
  body {
    overflow-y: scroll;
    background-color: ${props => props.theme.palette.background};
    margin: 0;
    padding: 0;
    font-weight: 300;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-smoothing: antialiased;
  }
    
  input {
    padding: 6px;
  }
    
  #root {
    display: flex;
    justify-content: center;
  }
`

const AppWrapper = styled.div`
  height: 100%;
  margin-bottom: 50px;
  
  @media (min-width: ${props => props.theme.sizes.mobile_size}) {
    width: 60%;
    max-width: 800px;
  }

  @media (max-width: ${props => props.theme.sizes.mobile_size}) {
    width: 95%;
  }
`

const HeaderWrapper = styled.div`
  height: 25vh;
`

/* MobX */

const stores = {
  authStore,
  ideaStore
}

class App extends Component {
  render() {
    const loginCheckResult = loginCheck()
    const decodedToken = loginCheckResult.decodedToken
    const loggedIn = loginCheckResult.loggedIn

    if (loggedIn && decodedToken) {
      stores.authStore.setId(decodedToken.id)
      stores.authStore.setFirstName(decodedToken.firstName)
      stores.authStore.setLastName(decodedToken.lastName)
      stores.authStore.setEmail(decodedToken.email)
      stores.authStore.setAdmin(decodedToken.admin)
      stores.authStore.setLoggedIn(true)
    } else {
      stores.authStore.setLoggedIn(false)
    }

    return (
      <Provider {...stores}>
        <ThemeProvider theme={theme}>
          <AppWrapper>
            <GlobalStyle />
            <HeaderWrapper>
              <Header />
            </HeaderWrapper>
            <Switch>
              <PrivateRoute
                exact
                path="/"
                component={Home}
                loggedIn={stores.authStore.loggedIn}
              />
              <PrivateRoute
                exact
                path="/post"
                component={PostIdea}
                loggedIn={stores.authStore.loggedIn}
              />
              <PrivateRoute
                exact
                path="/idea/:id"
                component={ViewIdea}
                loggedIn={stores.authStore.loggedIn}
              />
              <PrivateRoute
                exact
                path="/pwd"
                component={ResetPasswordWithoutToken}
                loggedIn={stores.authStore.loggedIn}
              />
              <PrivateRoute
                exact
                path="/useroverview"
                component={UserOverview}
                loggedIn={stores.authStore.loggedIn}
              />
              <Route exact path="/login" component={Login} />
              <Route exact path="/register" component={Registration} />
              <Route exact path="/verify/:id/:token" component={Verification} />
              <Route exact path="/forgotpwd" component={SendPasswordResetMail} />
              <Route exact path="/pwd/:id/:token" component={ResetPassword} />
              <Route exact path="/msg" component={Message} />
            </Switch>
          </AppWrapper>
        </ThemeProvider>
      </Provider>
    )
  }
}
export default App
