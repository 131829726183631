import React, {Component} from 'react'
import styled from 'styled-components'

const EurekaTag = styled.div`
  background: ${props => props.theme.palette.light_yellow};
  color: ${props => props.theme.palette.dark_yellow};
  display: inline-block;
  margin: 0px;
  margin-top: 3px;
  margin-right: 3px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 25px;
`

const EurekaTagText = styled.p`
    margin: 0;
`

class DisplayTag extends Component {
  render() {
    return (
      <EurekaTag>
        <EurekaTagText><small>{this.props.children}</small></EurekaTagText>
      </EurekaTag>
    )
  }
}

export default DisplayTag
