import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import {ApolloProvider} from 'react-apollo'
import {ApolloClient} from 'apollo-client'
import {createHttpLink} from 'apollo-link-http'
import {InMemoryCache} from 'apollo-cache-inmemory'
import {split} from 'apollo-link'
import {WebSocketLink} from 'apollo-link-ws'
import {getMainDefinition} from 'apollo-utilities'
import {BrowserRouter} from 'react-router-dom'
import {setContext} from 'apollo-link-context'
import {AUTH_TOKEN} from './constants'
import JavascriptTimeAgo from 'javascript-time-ago'
import nl from 'javascript-time-ago/locale/nl'

JavascriptTimeAgo.locale(nl)

console.info('REACT_APP_ENV: ' + process.env.REACT_APP_ENV)

let backendHTTPHost = ''
let backendWSHost = ''
if (process.env.REACT_APP_ENV === 'acc') {
  backendHTTPHost = 'https://eureka-be-test.jstack.eu'
  backendWSHost = 'wss://eureka-be-test.jstack.eu/graphql'
}
if (process.env.REACT_APP_ENV === 'dev') {
  backendHTTPHost = 'http://localhost:7000'
  backendWSHost = 'ws://localhost:7000/graphql'
}

const httpLink = createHttpLink({
  uri: `${backendHTTPHost}`
})

const authLink = setContext((_, {headers}) => {
  return {
    headers: {
      ...headers,
      authorization: getToken() ? `Bearer ${getToken()}` : ''
    }
  }
})

function getToken() {
  return localStorage.getItem(AUTH_TOKEN)
}

// https://github.com/apollographql/subscriptions-transport-ws/issues/171
const wsLink = new WebSocketLink({
  uri: `${backendWSHost}`,
  options: {
    reconnect: true,
  }
})

const subscriptionMiddleware = {
  applyMiddleware: async (options, next) => {
    options.Authorization = getToken()
    next()
  },
}

wsLink.subscriptionClient.use([subscriptionMiddleware])

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  authLink.concat(httpLink)
)

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache()
})

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
serviceWorker.unregister()
