import React, {Component} from 'react'
import {Mutation} from 'react-apollo'
import gql from 'graphql-tag'
import styled from 'styled-components'
import Button from '../../shared/components/button'
import Spinner from '../../shared/components/spinner'
import mascotte from '../../assets/mascotte_login.png'
import {
  Wrapper,
  StyledInput,
  AboveInput,
  AboveInputLabel,
  LoginMascotte,
  SpinnerText
} from './style/style'
import {inject, observer} from 'mobx-react'
import AuthNotification from '../../shared/components/authNotification'
import {Redirect, Link} from 'react-router-dom'

/* Apollo constants */

const SIGNUP_MUTATION = gql`
  mutation SignupMutation(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
  ) {
    signup(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
    ) {
      email
    }
  }
`

/* styled components */

export const BelowInput = styled.div`
  width: 30vw;
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
`

@inject('authStore')
@observer
class Registration extends Component {
  constructor(props) {
    super(props)
    this.registerButtonRef = React.createRef()
  }

  confirm = async data => {
    this.props.authStore.setWaitingForVerification(true)
  }

  keyPress = (e) => {
    if(e.keyCode === 13) {
      if (this.registerButtonRef.props) this.registerButtonRef.props.onClick()
    }
  }

  render() {
    return (
      <div>
        <div>
          <Wrapper>
            <AboveInput>
              <LoginMascotte src={mascotte} />
              <AboveInputLabel>
                <Link to={'/login'}>Klik hier om in te loggen</Link>
              </AboveInputLabel>
            </AboveInput>

            <StyledInput
              value={this.props.authStore.firstName}
              onChange={e => this.props.authStore.setFirstName(e.target.value)}
              type="text"
              placeholder="Voornaam"
            />
            <br />
            <StyledInput
              value={this.props.authStore.lastName}
              onChange={e => this.props.authStore.setLastName(e.target.value)}
              type="text"
              placeholder="Achternaam"
            />
            <br />
            <StyledInput
              value={this.props.authStore.email}
              onChange={e => this.props.authStore.setEmail(e.target.value)}
              type="text"
              placeholder="E-mailadres"
            />
            <br />
            <StyledInput
              value={this.props.authStore.password}
              onChange={e => this.props.authStore.setPassword(e.target.value)}
              type="password"
              placeholder="Wachtwoord"
            />
            <br />
            <StyledInput
              value={this.props.authStore.passwordVerification}
              onChange={e =>
                this.props.authStore.setPasswordVerification(e.target.value)
              }
              type="password"
              placeholder="Wachtwoord bevestigen"
              onKeyDown={this.keyPress}
            />

            <AuthNotification>
              {(this.props.password !== '' && !this.props.authStore.passwordsValid) &&
                'De wachtwoorden komen niet overeen.'}
            </AuthNotification>

            <Mutation
              mutation={SIGNUP_MUTATION}
              variables={{
                firstName: this.props.authStore.firstName,
                lastName: this.props.authStore.lastName,
                email: this.props.authStore.email,
                password: this.props.authStore.password
              }}
              onCompleted={data => this.confirm(data)}>
              {(mutation, {loading, error}) => (
                <React.Fragment>
                  <BelowInput>
                    {this.props.authStore.passwordsValid &&
                    this.props.authStore.allRegisterFieldsComplete ? (
                      <Button ref={elem => this.registerButtonRef = elem} onClick={mutation}>
                        {loading && (
                          <SpinnerText data-testid={'registration_submit_spinner'}>
                            <Spinner />
                          </SpinnerText>
                        )}
                        Account maken
                      </Button>
                    ) : (
                      <Button lightgrey disabled>
                        Account maken
                      </Button>
                    )}
                  </BelowInput>
                  
                  <AuthNotification>
                    {error && 'Er ging iets mis!'}
                  </AuthNotification>
                  {this.props.authStore.waitingForVerification &&
                    <Redirect to={{pathname: '/msg', state: {type: 'success', showLoginLink: true, text: 'Je bent geregistreerd. Bevestig je e-mailadres om door te gaan.'}}} />
                  }
                </React.Fragment>
              )}
            </Mutation>
          </Wrapper>
        </div>
      </div>
    )
  }
}

export default Registration
