import React, {Component} from 'react'
import {Query} from 'react-apollo'
import gql from 'graphql-tag'
import {inject, observer} from 'mobx-react'
import {withRouter} from 'react-router'
import styled from 'styled-components'
import InfiniteScroll from 'react-infinite-scroller'
import Spinner from '../../shared/components/spinner'
import CommentItem from './commentItem'
import {LoadMoreContainer} from './style/style'
import {INITIAL_AMOUNT_OF_LOADED_COMMENTS} from '../../constants'

const PAGINATED_COMMENTS_QUERY = gql`
  query PaginatedCommentQuery(
    $id: String!
    $commentId: String
    $limit: Int
    $cursor: String
  ) {
    paginatedCommentsForIdea(
      id: $id
      commentId: $commentId
      limit: $limit
      cursor: $cursor
    ) {
      edges {
        id
        createdAt
        commentId
        ideaId
        text
        user {
          id
          firstName
          lastName
        }
        likedComments {
          id
          firstName
          lastName
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

const NEW_COMMENTS_SUBSCRIPTION = gql`
  subscription {
    newComment {
      id
      createdAt
      commentId
      ideaId
      text
      user {
        id
        firstName
        lastName
      }
      likedComments {
        id
        firstName
        lastName
      }
    }
  }
`

const COMMENT_LIKE_CHANGED_SUBSCRIPTION = gql`
  subscription {
    commentLikeChanged {
      id
      createdAt
      commentId
      ideaId
      text
      user {
        id
        firstName
        lastName
      }
      likedComments {
        id
        firstName
        lastName
      }
    }
  }
`

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px;
  position: relative;
`

const NoReactionsContainer = styled.div`
  display: flex;
  justify-content: center;
`

@inject('authStore')
@observer
class CommentSection extends Component {
  state = {
    shouldLoad: false
  }

  subscribeToNewLikes = subscribeToMore => {
    subscribeToMore({
      document: COMMENT_LIKE_CHANGED_SUBSCRIPTION
    })
  }

  subscribeToNewComments = subscribeToMore => {
    subscribeToMore({
      document: NEW_COMMENTS_SUBSCRIPTION,
      updateQuery: (prev, {subscriptionData}) => {
        if (!subscriptionData.data) return prev
        const newComment = subscriptionData.data.newComment
        const exists = prev.paginatedCommentsForIdea.edges.find(
          ({id}) => id === newComment.id
        )
        if (exists) return prev
        if (this.props.commentId != newComment.commentId) return prev
        //only show new comment if it's from the user currently signed in
        if (this.props.authStore.id != newComment.user.id) return prev

        return Object.assign({}, prev, {
          paginatedCommentsForIdea: {
            edges: [newComment, ...prev.paginatedCommentsForIdea.edges],
            pageInfo: {
              endCursor: prev.paginatedCommentsForIdea.pageInfo.endCursor,
              hasNextPage: prev.paginatedCommentsForIdea.pageInfo.hasNextPage,
              __typename: prev.paginatedCommentsForIdea.__typename
            },
            __typename: prev.paginatedCommentsForIdea.__typename
          }
        })
      }
    })
  }

  fetchMoreComments = (fetchMore, data) => {
    fetchMore({
      variables: {
        id: this.props.ideaId,
        commentId: this.props.commentId,
        limit: INITIAL_AMOUNT_OF_LOADED_COMMENTS,
        cursor: data.paginatedCommentsForIdea.pageInfo.endCursor
      },

      updateQuery: (previousResult, {fetchMoreResult}) => {
        if (!fetchMoreResult) {
          return previousResult
        }

        const obj = Object.assign({}, fetchMoreResult, previousResult, {
          paginatedCommentsForIdea: {
            edges: [
              ...previousResult.paginatedCommentsForIdea.edges,
              ...fetchMoreResult.paginatedCommentsForIdea.edges
            ],
            pageInfo: {
              endCursor:
                fetchMoreResult.paginatedCommentsForIdea.pageInfo.endCursor,
              hasNextPage:
                fetchMoreResult.paginatedCommentsForIdea.pageInfo.hasNextPage,
              __typename: fetchMoreResult.paginatedCommentsForIdea.__typename
            },
            __typename: previousResult.paginatedCommentsForIdea.__typename
          }
        })
        return obj
      }
    })
  }

  getCommentsToRender = data => {
    data = data.paginatedCommentsForIdea.edges
    return data
  }

  render() {

    return (
      <div>
        <Query
          query={PAGINATED_COMMENTS_QUERY}
          variables={{
            id: this.props.ideaId,
            commentId: this.props.commentId,
            limit: INITIAL_AMOUNT_OF_LOADED_COMMENTS,
            cursor: null
          }}>
          {({loading, error, data, subscribeToMore, fetchMore}) => {
            if (loading) return <div>Laden...</div>
            if (error) return <div>Er liep iets mis :(</div>

            this.subscribeToNewLikes(subscribeToMore)
            this.subscribeToNewComments(subscribeToMore)

            const commentsToRender = this.getCommentsToRender(data)

            return (
              <div>
                {this.props.depth === 0 && (
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={() => this.fetchMoreComments(fetchMore, data)}
                    hasMore={data.paginatedCommentsForIdea.pageInfo.hasNextPage}
                    loader={
                      <LoadingContainer key={0} data-testid={'loading_spinner_comments_scroll'}>
                        <Spinner />
                      </LoadingContainer>
                    }>
                    {commentsToRender.length < 1 && this.props.depth < 1 && (
                      <NoReactionsContainer>
                        <p>Nog geen reacties.</p>
                      </NoReactionsContainer>
                    )}
                    {commentsToRender.map(comment => (
                      <CommentItem
                        ideaId={this.props.ideaId}
                        key={comment.id}
                        comment={comment}
                        depth={this.props.depth}
                        data-testid={'comment_item_list'}
                      />
                    ))}
                  </InfiniteScroll>
                )}

                {this.props.depth > 0 && (
                  <React.Fragment>
                    {commentsToRender.map(comment => (
                      <CommentItem
                        ideaId={this.props.ideaId}
                        key={comment.id}
                        comment={comment}
                        depth={this.props.depth}
                        data-testid={'comment_item_list'}
                      />
                    ))}
                    {data.paginatedCommentsForIdea.pageInfo.hasNextPage && (
                      <LoadMoreContainer
                        onClick={() => this.fetchMoreComments(fetchMore, data)}>
                        <small>Meer antwoorden weergeven</small>
                      </LoadMoreContainer>
                    )}
                  </React.Fragment>
                )}
              </div>
            )
          }}
        </Query>
      </div>
    )
  }
}

export default withRouter(CommentSection)
