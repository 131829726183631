import styled, {css} from 'styled-components'
import Input from '../../shared/components/input'

export const WideInput = styled(Input)`
  width: 100%;
  box-sizing: border-box;
`

export const Mascotte = styled.img`
  height: 6em;
  padding-left: 3em;
`

export const Title = styled.h4`
  color: ${props => props.theme.palette.grey};
`

export const IdeaDescriptionSmall = styled.div`
  height: 5em;
  overflow: hidden;
  position: relative;
  word-break: break-all;

  &:after {
    position: absolute;
    bottom: 0;  
    height: 100%;
    width: 100%;
    content: "";
    background: linear-gradient(to top,
      rgba(255,255,255, 1) 5%, 
      rgba(255,255,255, 0) 30%
    );
    pointer-events: none;
  }
`

export const IdeaHolder = styled.div`
  background: white;
  padding: 10px;
  margin: 0;
  border-bottom: 1px solid ${props => props.theme.palette.light_grey};

  &:hover {
    background: ${props => props.theme.palette.super_light_grey};
  }

  &:hover ${IdeaDescriptionSmall} {
    &:after {
      background: linear-gradient(to top,
        ${props => props.theme.palette.super_light_grey} 5%, 
        rgba(255,255,255, 0) 30%
      );
    }
  }

  ${props =>
      props.selected &&
      css`
        border-left: 2px solid ${props => props.theme.palette.dark_green};
  `}

  ${props =>
      props.archived &&
      css`
        border-left: 2px solid ${props => props.theme.palette.dark_red};
  `}
`

export const ClickableIdeaPart = styled.div`
  cursor: pointer;
`

export const IdeaContainer = styled.div`
  background: white;
  padding: 10px;
  margin: 0;
  border-bottom: 1px solid ${props => props.theme.palette.light_grey};
`

export const IdeaHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  margin: 5px 0px 5px 0px;
`

export const TitleSorter = styled.div`
  display: flex;
`

export const IdeaText = styled.p`
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-bottom: 0px;

  ${props =>
      props.grey &&
      css`
        color: ${props => props.theme.palette.grey};
  `}

  ${props =>
      props.accepted &&
      css`
        color: ${props => props.theme.palette.dark_green};
        padding-left: 5px;
  `}

  ${props =>
      props.archived &&
      css`
        color: ${props => props.theme.palette.dark_red};
        padding-left: 5px;
  `}
`

export const IdeaDescriptionLarge = styled.div`
  position: relative;
  overflow: auto;
  word-break: break-all;
  width: 100%;
`

export const IdeaFooter = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const IdeaActionGrouper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const IdeaFooterText = styled.p`
  padding-left: 20px;
  color: ${props => props.theme.palette.grey};
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 0px;

  &:hover {
    color: ${props => props.theme.palette.dark_grey};
  }
`

