import React, {Component} from 'react'
import {withRouter} from 'react-router'
import styled, {css} from 'styled-components'
import {inject, observer} from 'mobx-react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {AUTH_TOKEN} from '../../constants'
import OutsideClickHandler from 'react-outside-click-handler';

const DropdownContent = styled.div`
    background: #FFFFFF;
    padding: 0;
    display: none;
    position: absolute;
    top: 60px;
    right: 0;
    z-index: 1;
    width: 100%;
    cursor: pointer;

    &:after {
        content: " ";
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent ${props => props.theme.palette.light_grey} transparent;
    }
`

const DropdownItemHolder = styled.div`
    padding: 10px 20px 10px 20px;

    &:hover {
        background-color: ${props => props.theme.palette.super_light_grey};
    }

    ${props =>
      props.underline &&
      css`
        border-bottom: 2px solid ${props => props.theme.palette.light_grey};
    `}
`
const MenuTop = styled.div`
    background: #FFFFFF;
    padding: 10px 20px 10px 20px;
    border-bottom: 2px solid ${props => props.theme.palette.light_grey};
    display: inline-block;
    min-width: 180px;
    cursor: pointer;

    @media (min-width: ${props => props.theme.sizes.mobile_size}) {
        min-width: 180px
    }

    @media (max-width: ${props => props.theme.sizes.mobile_size}) {
        min-width: auto;
    }
`

const MenuTextItem = styled.p`
    display: flex;
    align-items: center;
    color: ${props => props.theme.palette.dark_grey};
    margin: 0;
`

const DisappearingTextPart = styled.div`
    @media (min-width: ${props => props.theme.sizes.mobile_size}) {
        display: auto;
    }

    @media (max-width: ${props => props.theme.sizes.mobile_size}) {
        display: none;
    }
`

@inject('authStore')
@observer
class Menu extends Component {
    constructor(props) {
        super(props)
        this.dropdownRef = React.createRef()
    }

    logOut = () => {
        const store = this.props.authStore
        store.setLoggedIn(false)
    
        localStorage.removeItem(AUTH_TOKEN)
        this.props.history.push('/')
    }

    switchToggle = () => {
        if (true == true) {
            if (this.dropdownRef.current.style.display === 'block') {
                this.dropdownRef.current.style.display = 'none'
            } else {
                this.dropdownRef.current.style.display = 'block'
            }
        }
    }

    closeMenu = () => {
        try {
            this.dropdownRef.current.style.display = 'none'
        } catch (ex) {
            // do nothing, element is not available currently
        }
    }
    
    render() {
    return (
        <OutsideClickHandler onOutsideClick={this.closeMenu}>
        <div>
            {this.props.authStore.showMenu && <MenuTop {...this.props} onClick={this.switchToggle}>
                <MenuTextItem data-testid={'menu_item'}>
                    <FontAwesomeIcon icon="user-circle"/>
                    <DisappearingTextPart>&nbsp;&nbsp;{this.props.authStore.firstName} {this.props.authStore.lastName}</DisappearingTextPart>
                </MenuTextItem>
                <DropdownContent ref={this.dropdownRef}>
                    <DropdownItemHolder underline onClick={() => this.props.history.push('/pwd')}>
                        <MenuTextItem>
                            <FontAwesomeIcon icon="key"/>
                            <DisappearingTextPart>&nbsp;&nbsp;Wachtwoord</DisappearingTextPart>
                        </MenuTextItem>
                    </DropdownItemHolder>
                    {this.props.authStore.admin &&
                        <DropdownItemHolder underline onClick={() => this.props.history.push('/useroverview')}>
                            <MenuTextItem>
                                <FontAwesomeIcon icon="users"/>
                                <DisappearingTextPart>&nbsp;&nbsp;Gebruikers</DisappearingTextPart>
                            </MenuTextItem>
                        </DropdownItemHolder>
                    }
                    <DropdownItemHolder underline onClick={this.logOut}>
                        <MenuTextItem>
                            <FontAwesomeIcon icon="sign-out-alt"/>
                            <DisappearingTextPart>&nbsp;&nbsp;Uitloggen</DisappearingTextPart>
                        </MenuTextItem>
                    </DropdownItemHolder>
                </DropdownContent>
            </MenuTop>}                
        </div>
        </OutsideClickHandler>
    )
  }
}

export default withRouter(Menu)
