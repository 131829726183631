import {AUTH_TOKEN} from '../../constants'
import jwt from 'jsonwebtoken'

export function loginCheck() {
    const token = localStorage.getItem(AUTH_TOKEN)
    let decodedToken;
    let loggedIn = false

    if (token) {
        decodedToken = jwt.decode(token)
        const expireDate = new Date(0)
        expireDate.setUTCSeconds(decodedToken.exp)
        if (expireDate < Date.now()) {
            loggedIn = false
        } else {
            loggedIn = true
        }   
    } else {
        loggedIn = false
    }
    return {loggedIn, decodedToken}
}