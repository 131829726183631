import React, {PureComponent} from 'react'
import styled, {css} from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {ApolloConsumer} from 'react-apollo'
import gql from 'graphql-tag'

const LIKE_IDEA_MUTATION = gql`
  mutation LikeIdeaMutation($ideaId: String!) {
    likeIdea(ideaId: $ideaId)
  }
`

const LIKE_COMMENT_MUTATION = gql`
  mutation LikeCommentMutation($commentId: String!) {
    likeComment(commentId: $commentId)
  }
`

const HeartContainer = styled.div`
  color: ${props => props.theme.palette.grey};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.palette.dark_pink};
  }

  ${props =>
      props.selected &&
      css`
        color: ${props => props.theme.palette.pink};
  `}
`

class HeartButton extends PureComponent {
  state = {
    selected: this.props.selected
  }

  render() {
    const handleClick = async(client) => {
      this.setState(() => ({
        selected: !this.state.selected
      }))

      // if used to like an idea
      if (this.props.ideaId) {
        const { data } = await client.mutate({
          mutation: LIKE_IDEA_MUTATION,
          variables: { ideaId: this.props.ideaId }
        })
      }
      // if used to like a comment
      if (this.props.commentId) {
        const { data } = await client.mutate({
          mutation: LIKE_COMMENT_MUTATION,
          variables: { commentId: this.props.commentId }
        })
      }
    }

    return (
      <ApolloConsumer>
        {client => (
          <HeartContainer onClick={() => handleClick(client)} selected={this.state.selected}>
            <FontAwesomeIcon icon="heart" />
            {' '}{this.props.likes}
          </HeartContainer>
        )}
      </ApolloConsumer>
    )
  }
}

export default HeartButton
