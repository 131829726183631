import React, {Component} from 'react'
import {Mutation} from 'react-apollo'
import gql from 'graphql-tag'
import Button from '../../shared/components/button'
import Spinner from '../../shared/components/spinner'
import mascotte from '../../assets/mascotte_login.png'
import styled from 'styled-components'
import {
  Wrapper,
  StyledInput,
  AboveInput,
  AboveInputLabel,
  LoginMascotte,
  SpinnerText
} from './style/style'
import {inject, observer} from 'mobx-react'
import AuthNotification from '../../shared/components/authNotification'
import {Redirect, Link} from 'react-router-dom'

/* Apollo constants */

const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPasswordMutation($email: String!) {
    forgotPassword(email: $email)
  }
`

/* styled components */

export const BelowInput = styled.div`
  width: 30vw;
  display: flex;
  justify-content: space-between;
  align-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
`
@inject('authStore')
@observer
class SendPasswordResetMail extends Component {
  constructor(props) {
    super(props)
    this.sendButtonRef = React.createRef()
  }

  keyPress = (e) => {
    if(e.keyCode === 13) {
      if (this.sendButtonRef.props) this.sendButtonRef.props.onClick()
    }
  }

  componentDidMount() {
    this.props.authStore.setPassword('')
    this.props.authStore.setPasswordVerification('')
  }

  render() {
    return (
      <div>
        <div>
          <Wrapper>
            <AboveInput>
              <LoginMascotte src={mascotte} />
              <AboveInputLabel>
                Geef je e-mail in om een nieuw wachtwoord in te stellen.
              </AboveInputLabel>
            </AboveInput>

            <StyledInput
              value={this.props.authStore.email}
              onChange={e => this.props.authStore.setEmail(e.target.value)}
              type="text"
              placeholder="E-mailadres"
              onKeyDown={this.keyPress}
            />
            
            <Mutation
              mutation={FORGOT_PASSWORD_MUTATION}
              variables={{
                email: this.props.authStore.email
              }}
              onCompleted={() => this.props.authStore.setEmail('')}>
              {(mutation, {loading, error, data}) => (
                <React.Fragment>
                  <BelowInput>
                    <p>
                      <Link to={'/login'}>
                        Inloggen
                      </Link>
                    </p>

                    <div>
                      {this.props.authStore.email ? (
                        <Button ref={elem => this.sendButtonRef = elem} onClick={mutation}>
                          {loading && (
                            <SpinnerText>
                              <Spinner />
                            </SpinnerText>
                          )}
                          E-mail verzenden
                        </Button>
                      ) : (
                        <Button lightgrey disabled>
                          E-mail verzenden
                        </Button>
                      )}
                    </div>
                  </BelowInput>
                  <AuthNotification>
                    {error && 'Er ging iets mis!'}
                  </AuthNotification>
                  {data &&
                    <Redirect to={{pathname: '/msg', state: {type: 'success', showLoginLink: true, text: 'E-mail is verstuurd naar ' + data.forgotPassword + '.'}}} />
                  }
                </React.Fragment>
              )}
            </Mutation>
          </Wrapper>
        </div>
      </div>
    )
  }
}

export default SendPasswordResetMail
