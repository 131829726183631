import React, {PureComponent} from 'react'
import styled, {css} from 'styled-components'

const Tab = styled.div`
  color: ${props => props.theme.palette.dark_grey};
  cursor: pointer;
  background: ${props => props.theme.palette.light_grey};
  border-radius: 25px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  display: flex;

  ${props =>
      props.left &&
      css`
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: 1px solid ${props => props.theme.palette.dark_green};
        align-items: flex-end;
        padding-left: 15px;
      `
  }

  ${props =>
      props.right &&
      css`
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-left: 1px solid ${props => props.theme.palette.dark_green};
        align-items: flex-start;
        padding-right: 15px;
      `
  }

  ${props =>
      props.selected &&
      css`
        color: ${props => props.theme.palette.dark_green};
        background: ${props => props.theme.palette.light_green};
      `
  }
`

const Text = styled.p`
    margin-top: 9px;
    margin-bottom: 9px;
`

class FilterTab extends PureComponent {

  render() {
    return (
      <Tab {...this.props}>
        <Text>{this.props.children}</Text>
      </Tab>
    )
  }
}

export default FilterTab
