import {observable, action, computed} from 'mobx'

class IdeaStore {
  @observable ideaTitle = ''
  @observable ideaDescription = ''
  @observable ideaTags = []
  @observable editableIdeaTags = []
  @observable searchQuery = ''
  @observable viewMode = 'mostRecent'

  @action setIdeaTitle(ideaTitle) {
    this.ideaTitle = ideaTitle
    this.checkIfIsPostingIdea()
  }

  @action setIdeaDescription(ideaDescription) {
    this.ideaDescription = ideaDescription
    this.checkIfIsPostingIdea()
  }

  @action setIsPostingIdea(isPosting) {
    this.isPostingIdea = isPosting
  }

  @action setViewMode(viewMode) {
    this.viewMode = viewMode
    if (viewMode !== 'search') this.searchQuery = ''
  }

  @action setSearchQuery(searchQuery) {
    this.searchQuery = searchQuery
  }

  @action modifyTag(tagid) {
    if (this.ideaTags.includes(tagid)) {
      this.ideaTags = this.ideaTags.filter(e => e !== tagid)
    } else {
      this.ideaTags.push(tagid)
    }
  }

  @action addEditableIdeaTag(tag) {
    this.editableIdeaTags.push(tag)
  }

  @action removeEditableIdeaTag(tag) {
    this.editableIdeaTags = this.editableIdeaTags.filter(t => {
      return t.id !== tag.id
    })
  }

  @action closeIdeaPost() {
    this.ideaTitle = ''
    this.ideaDescription = '<p><br></p>'
    this.ideaTags = []
    this.editableIdeaTags = []
    this.viewMode = 'mostRecent'
  }

  @computed get allPostFieldsValid() {
    return (
      this.ideaTitle &&
      this.ideaDescription !== '<p><br></p>'
    )
  }

  checkIfIsPostingIdea() {
    if (this.ideaTitle.length > 0 || (this.ideaDescription.length > 0 && this.ideaDescription !== '<p><br></p>')) {
      this.setViewMode('posting')
    } else {
      this.setViewMode('mostRecent')
    }
    this.isPostingIdea = false
  }
}

export default new IdeaStore()
