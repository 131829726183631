import React, {Component} from 'react'
import {Query} from 'react-apollo'
import gql from 'graphql-tag'

const HOW_MANY_COMMENTS_FOR_IDEA_QUERY = gql`
  query HowManyCommentsForIdeaQuery($ideaId: String!) {
    howManyCommentsForIdea(ideaId: $ideaId)
  }
`

class CommentCount extends Component {
  render() {
    return (
      <React.Fragment>
        <Query
          query={HOW_MANY_COMMENTS_FOR_IDEA_QUERY}
          variables={{ideaId: this.props.ideaId}}>
          {({error, data}) => {
            if (error) console.log(error)

            return (
              <React.Fragment>{data.howManyCommentsForIdea}</React.Fragment>
            )
          }}
        </Query>
      </React.Fragment>
    )
  }
}

export default CommentCount
