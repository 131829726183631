import React, {Component} from 'react'
import {Mutation} from 'react-apollo'
import gql from 'graphql-tag'
import Button from '../../shared/components/button'
import Spinner from '../../shared/components/spinner'
import mascotte from '../../assets/mascotte_login.png'
import {
  Wrapper,
  StyledInput,
  AboveInput,
  AboveInputLabel,
  LoginMascotte,
  SpinnerText,
  BelowInput
} from './style/style'
import {inject, observer} from 'mobx-react'
import AuthNotification from '../../shared/components/authNotification'
import {Redirect, Link} from 'react-router-dom'

const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPasswordMutation($id: String!, $token: String!, $password: String!) {
    resetPassword(id: $id, token: $token, password: $password)
  }
`

@inject('authStore')
@observer
class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.resetButtonRef = React.createRef()
  }

  keyPress = (e) => {
    if(e.keyCode === 13) {
      if (this.resetButtonRef.props) this.resetButtonRef.props.onClick()
    }
  }

  render() {
    const id = this.props.match.params.id
    const token = this.props.match.params.token

    return (
      <div>
        <div>
          <Wrapper>
            <AboveInput>
              <LoginMascotte src={mascotte} />
              <AboveInputLabel>
                Kies een nieuw wachtwoord
              </AboveInputLabel>
            </AboveInput>

            <StyledInput
              value={this.props.authStore.password}
              onChange={e => this.props.authStore.setPassword(e.target.value)}
              type="password"
              placeholder="Wachtwoord"
            />
            <br />
            <StyledInput
              value={this.props.authStore.passwordVerification}
              onChange={e =>
                this.props.authStore.setPasswordVerification(e.target.value)
              }
              type="password"
              placeholder="Wachtwoord bevestigen"
              onKeyDown={this.keyPress}
            />
            
            <Mutation
              mutation={RESET_PASSWORD_MUTATION}
              variables={{
                id: id,
                token: token,
                password: this.props.authStore.password
              }}
              onCompleted={() => this.props.authStore.setLoggedIn(false)}>
              {(mutation, {loading, error, data}) => (
                <React.Fragment>
                  <BelowInput>
                    <p>
                      <Link to={'/login'}>
                        Inloggen
                      </Link>
                    </p>
                    <div>
                      {!data && this.props.authStore.password && this.props.authStore.passwordsValid ? (
                        <Button ref={elem => this.resetButtonRef = elem} onClick={mutation}>
                          {loading && (
                            <SpinnerText>
                              <Spinner />
                            </SpinnerText>
                          )}
                          Wachtwoord instellen
                        </Button>
                      ) : (
                        <Button lightgrey disabled>
                          Wachtwoord instellen
                        </Button>
                      )}
                    </div>
                  </BelowInput>
                  <AuthNotification>
                    {error && 'Er ging iets mis!'}
                  </AuthNotification>
                  <AuthNotification>
                    {!this.props.authStore.passwordsValid &&
                      'De wachtwoorden komen niet overeen.'}
                  </AuthNotification>
                  {data &&
                    <Redirect to={{pathname: '/msg', state: {type: 'success', showLoginLink: true, text: 'Je wachtwoord is opnieuw ingesteld. Je kan nu inloggen.'}}} />
                  }
                </React.Fragment>
              )}
            </Mutation>
          </Wrapper>
        </div>
      </div>
    )
  }
}

export default ResetPassword
