import React, {Component} from 'react'
import {Wrapper} from '../../components/authentication/style/style'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styled, {css} from 'styled-components'
import {Link} from 'react-router-dom'

const IconHolder = styled.div`
  ${props =>
      props.success &&
      css`
        color: ${props => props.theme.palette.dark_green};
  `}
`

const MessageHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

class Message extends Component {
  render() {    
    const type = this.props.location.state.type
    const text = this.props.location.state.text

    return (
      <Wrapper>
        <MessageHolder>
          <IconHolder success={type === 'success'} error={type === 'error'}>
            {type === 'success' && <FontAwesomeIcon icon="thumbs-up" />}
            {type === 'error' && <FontAwesomeIcon icon="sad-cry" />}
          </IconHolder>
          <p>&nbsp;{text}</p>
        </MessageHolder>
        <br />
        {this.props.location.state.showLoginLink && <Link to="/login">Klik hier om in te loggen</Link>}
        {this.props.location.state.showHomePageLink && <Link to="/">Klik hier om terug te gaan</Link>}
      </Wrapper>
    )
  }
}

export default Message
