import React, {Component} from 'react'
import styled from 'styled-components'
import {SpinnerText} from '../authentication/style/style'
import Spinner from '../../shared/components/spinner'
import {Mutation} from 'react-apollo'
import gql from 'graphql-tag'
import Button from '../../shared/components/button'

const ADD_COMMENT_MUTATION = gql`
  mutation AddCommentMutation(
    $ideaId: String!
    $text: String!
    $commentId: String
  ) {
    addComment(ideaId: $ideaId, text: $text, commentId: $commentId) {
      id
      commentId
      ideaId
      text
      createdAt
      user {
        id
        firstName
        lastName
      }
    }
  }
`

const CommentArea = styled.div`
  padding: 5px 0 0 0;
`

const CommentTextArea = styled.textarea`
  background: ${props => props.theme.palette.background};
  padding: 6px;
  width: 100%;
  height: 6em;
  border: none;
  resize: none;
  border: 1px solid ${props => props.theme.palette.light_grey};
  font-size: 1em;
`

const BelowCommentTextArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

const ButtonWithLeftMargin = styled(Button)`
  margin-left: 1em;
`

class PostComment extends Component {
  constructor(props) {
    super(props)
    this.postButtonRef = React.createRef()
    this.editFieldRef = React.createRef()
  }

  componentDidMount(){
    if (this.props.focusOnMount) {
      this.editFieldRef.focus()
    }
  }

  keyPress = (e) => {
    if(e.keyCode === 13) {
      if (this.postButtonRef.props) this.postButtonRef.props.onClick()
    }
  }

  state = {
    text: ''
  }

  render() {
    const clearReaction = () => {
      setCommentText('')
      this.props.toggleCommenting()
    }

    const setCommentText = text => {
      this.setState(() => ({
        text: text
      }))
    }

    return (
      <CommentArea>
        <CommentTextArea
          placeholder="Reactie"
          value={this.state.text}
          onChange={() => setCommentText(event.target.value)}
          onKeyDown={this.keyPress}
          ref={elem => this.editFieldRef = elem}
        />

        <Mutation
          mutation={ADD_COMMENT_MUTATION}
          variables={{
            ideaId: this.props.comment.ideaId,
            text: this.state.text,
            commentId: this.props.comment.id
          }}
          onCompleted={() => clearReaction()}>
          {(mutation, {loading, error}) => (
            <React.Fragment>
              <BelowCommentTextArea>
                {this.state.text !== '' ?
                  <ButtonWithLeftMargin grey onClick={() => clearReaction()}>
                    Annuleren
                  </ButtonWithLeftMargin>
                  :
                  <ButtonWithLeftMargin lightgrey disabled>
                    Annuleren
                  </ButtonWithLeftMargin>
                }
                {this.state.text !== '' && (
                  <React.Fragment>
                    
                    <ButtonWithLeftMargin ref={elem => this.postButtonRef = elem} green onClick={mutation} data-testid={'comment_item_reaction_submit_button'}>
                      {loading && (
                        <SpinnerText>
                          <Spinner />
                        </SpinnerText>
                      )}
                      Opslaan
                    </ButtonWithLeftMargin>
                  </React.Fragment>
                )}
                {this.state.text === '' && (
                  <React.Fragment>
                    <ButtonWithLeftMargin lightgrey disabled>
                      Opslaan
                    </ButtonWithLeftMargin>
                  </React.Fragment>
                )}
              </BelowCommentTextArea>
              <p>{error && 'Er ging iets mis!'}</p>
            </React.Fragment>
          )}
        </Mutation>
      </CommentArea>
    )
  }
}

export default PostComment
