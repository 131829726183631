import Input from '../../../shared/components/input'
import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const StyledInput = styled(Input)`
  @media (min-width: ${props => props.theme.sizes.mobile_size}) {
    width: 30vw;
  }

  @media (max-width: ${props => props.theme.sizes.mobile_size}) {
    width: 70vw;
    box-sizing: border-box;
  }
`

export const AboveInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: space-between;
  /*padding-bottom: 0px;*/

  @media (min-width: ${props => props.theme.sizes.mobile_size}) {
    width: 30vw;
  }

  @media (max-width: ${props => props.theme.sizes.mobile_size}) {
    width: 70vw;
  }
`

export const AboveInputLabel = styled.p`
  padding-left: 5vh;
`

export const LoginMascotte = styled.img`
  height: 5em;
`

export const SpinnerText = styled.p`
  display: inline-block;
  margin: 0px;
  padding: 0px;
  padding-right: 0.5em;
`

export const BelowInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: flex-end;
  align-items: center;
  flex-direction: row;

  @media (min-width: ${props => props.theme.sizes.mobile_size}) {
    width: 30vw;
  }

  @media (max-width: ${props => props.theme.sizes.mobile_size}) {
    width: 70vw;
  }
`
