import React, {Component} from 'react'
import IdeaItem from './ideaItem'
import {Query} from 'react-apollo'
import gql from 'graphql-tag'
import {inject, observer} from 'mobx-react'
import {withRouter} from 'react-router'
import styled from 'styled-components'
import InfiniteScroll from 'react-infinite-scroller'
import Spinner from '../../shared/components/spinner'
import {IDEAS_PER_PAGE} from '../../constants'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export const PAGINATED_IDEAS_QUERY = gql`
  query PaginatedFeedQuery($limit: Int, $cursor: String, $searchQuery: String) {
      paginatedIdeas(limit: $limit, cursor: $cursor, searchQuery: $searchQuery) {
        edges {
          createdAt
          accepted
          archived
          id
          title
          description
          comments{
            id
            text
            commentId
          }
          likedIdeas {
            id
            firstName
            lastName
          }
          user 
          {
            id
            firstName
            lastName
          }
          tags 
          {
            id
            text
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
`

const PAGINATED_MOST_POPULAR_IDEAS_QUERY = gql`
  query PaginatedMostPopularFeedQuery($limit: Int, $cursor: String, $searchQuery: String) {
      paginatedMostPopularIdeas(limit: $limit, cursor: $cursor, searchQuery: $searchQuery) {
        edges {
          createdAt
          accepted
          archived
          id
          title
          description
          comments{
            id
            text
            commentId
          }
          likedIdeas {
            id
            firstName
            lastName
          }
          user 
          {
            id
            firstName
            lastName
          }
          tags 
          {
            id
            text
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
`

const NEW_IDEAS_SUBSCRIPTION = gql`
  subscription {
    newIdea {
      createdAt
      accepted
      archived
      id
      title
      description
      comments{
        id
        text
        commentId
      }
      likedIdeas {
        id
        firstName
        lastName
      }
      user {
        id
        firstName
        lastName
      }
      tags {
        id
        text
      }
    }
  }
`

const IDEA_LIKE_CHANGED_SUBSCRIPTION = gql`
  subscription {
    ideaLikeChanged {
      id
      title
      description
      createdAt
      accepted
      archived
      likedIdeas {
        id
        firstName
        lastName
      }
      user {
        id
        firstName
        lastName
      }
      tags {
        id
        text
      }
    }
  }
`

const IDEA_STATE_CHANGED_SUBSCRIPTION = gql`
  subscription {
    ideaStateChanged {
      id
      title
      description
      createdAt
      accepted
      archived
      likedIdeas {
        id
        firstName
        lastName
      }
      user {
        id
        firstName
        lastName
      }
      tags {
        id
        text
      }
    }
  }
`

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px;
`

@inject('ideaStore')
@observer
class IdeaList extends Component {
  subscribeToNewLikes = subscribeToMore => {
    subscribeToMore({
      document: IDEA_LIKE_CHANGED_SUBSCRIPTION
    })
  }

  subscribeToStateChanges = subscribeToMore => {
    subscribeToMore({
      document: IDEA_STATE_CHANGED_SUBSCRIPTION,
    })
  }

  subscribeToNewIdeas = subscribeToMore => {
    subscribeToMore({
      document: NEW_IDEAS_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        if (this.props.ideaStore.viewMode === 'search') return prev
        if (this.props.ideaStore.viewMode === 'mostPopular') return prev
        const newIdea = subscriptionData.data.newIdea
        const exists = prev.paginatedIdeas.edges.find(({ id }) => id === newIdea.id)

        if (exists) return prev

        return Object.assign({}, prev, {
          paginatedIdeas: {
            edges: [newIdea, ...prev.paginatedIdeas.edges],
            pageInfo: {
              endCursor: prev.paginatedIdeas.pageInfo.endCursor,
              hasNextPage: prev.paginatedIdeas.pageInfo.hasNextPage,
              __typename: prev.paginatedIdeas.__typename
            },
            __typename: prev.paginatedIdeas.__typename
          }
        })
      }
    })
  }

  getIdeasToRender = data => {
    if (this.props.viewMode === 'mostRecent' || this.props.viewMode === 'search') {
      return data.paginatedIdeas.edges
    }
    if (this.props.viewMode === 'mostPopular') {
      return data.paginatedMostPopularIdeas.edges
    }
  }

  render() {
    return (
      <div>
        <Query query={this.props.viewMode === 'mostRecent' || this.props.viewMode === 'search' ? PAGINATED_IDEAS_QUERY : PAGINATED_MOST_POPULAR_IDEAS_QUERY} variables={{limit: IDEAS_PER_PAGE, cursor: null, searchQuery: this.props.ideaStore.searchQuery}}>
          {({loading, error, data, subscribeToMore, fetchMore}) => {
            if (loading) return <LoadingContainer key={1}><Spinner /></LoadingContainer>
            if (error) console.log(error)
            if (error) return <div>Er liep iets mis :(</div>

            this.subscribeToNewIdeas(subscribeToMore)
            this.subscribeToNewLikes(subscribeToMore)
            this.subscribeToStateChanges(subscribeToMore)

            const ideasToRender = this.getIdeasToRender(data)

            return (
              <div>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={
                      () =>
                      fetchMore({
                        variables: {
                          limit: IDEAS_PER_PAGE,
                          cursor: this.props.viewMode === 'mostRecent' || this.props.viewMode === 'search' ? data.paginatedIdeas.pageInfo.endCursor : data.paginatedMostPopularIdeas.pageInfo.endCursor,
                          searchQuery: this.props.ideaStore.searchQuery
                        },

                        updateQuery: (previousResult, { fetchMoreResult }) => {
                          if (!fetchMoreResult) { return previousResult }

                          return Object.assign({}, fetchMoreResult, previousResult,
                            // viewMode is mostRecent
                            this.props.viewMode === 'mostRecent' || this.props.viewMode === 'search'?
                            {
                              paginatedIdeas: {
                                edges: [...previousResult.paginatedIdeas.edges, ...fetchMoreResult.paginatedIdeas.edges],
                                pageInfo: {
                                  endCursor: fetchMoreResult.paginatedIdeas.pageInfo.endCursor,
                                  hasNextPage: fetchMoreResult.paginatedIdeas.pageInfo.hasNextPage,
                                  __typename: fetchMoreResult.paginatedIdeas.__typename
                                },
                                __typename: previousResult.paginatedIdeas.__typename
                              }
                            }
                            :
                            // viewMode is mostPopular
                            {
                              paginatedMostPopularIdeas: {
                                edges: [...previousResult.paginatedMostPopularIdeas.edges, ...fetchMoreResult.paginatedMostPopularIdeas.edges],
                                pageInfo: {
                                  endCursor: fetchMoreResult.paginatedMostPopularIdeas.pageInfo.endCursor,
                                  hasNextPage: fetchMoreResult.paginatedMostPopularIdeas.pageInfo.hasNextPage,
                                  __typename: fetchMoreResult.paginatedMostPopularIdeas.__typename
                                },
                                __typename: previousResult.paginatedMostPopularIdeas.__typename
                              }
                            }
                            )
                        }
                      })
                    }
                    hasMore={this.props.viewMode === 'mostRecent' || this.props.viewMode === 'search' ? data.paginatedIdeas.pageInfo.hasNextPage : data.paginatedMostPopularIdeas.pageInfo.hasNextPage}
                    loader={<LoadingContainer key={0} data-testid={'loading_spinner_ideas_scroll'}><Spinner /></LoadingContainer>}
                >
                  {
                    ideasToRender.length < 1 &&
                    <LoadingContainer><FontAwesomeIcon icon="sad-cry" />&nbsp;<p>Geen ideeën gevonden.</p></LoadingContainer>
                  }
                  {ideasToRender.map(idea => (
                    <IdeaItem key={idea.id} idea={idea} data-testid={'idea_item_list'} />
                  ))}
                </InfiniteScroll>

                
              </div>
            )
          }}
        </Query>
      </div>
    )
  }
}

export default withRouter(IdeaList)