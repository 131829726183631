import React, {Component} from 'react'
import CommentSection from './commentSection'
import styled from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import ReactTimeAgo from 'react-time-ago'
import {Query} from 'react-apollo'
import gql from 'graphql-tag'
import {LoadMoreContainer} from './style/style'
import {INITIAL_AMOUNT_OF_LOADED_COMMENTS} from '../../constants'
import PostComment from './postComment'
import HeartButton from '../../shared/components/heartButton'
import {inject, observer} from 'mobx-react'

const HOW_MANY_COMMENTS_FOR_COMMENT_QUERY = gql`
  query HowManyCommentsForCommentQuery($commentId: String!) {
    howManyCommentsForComment(commentId: $commentId)
  }
`

const Title = styled.p`
  color: ${props => props.theme.palette.dark_green};
  margin: 8px 0 8px 0;
  font-weight: bold;
`

const CommentContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
`

const Container = styled.div`
  padding-left: 20px;
  border-left: 2px solid ${props => props.theme.palette.light_grey};
`

const CommentText = styled.p`
  margin: 0;
  padding: 0 0 4px 0;
`

const BelowCommentText = styled.div`
  padding: 5px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const BelowCommentTextItem = styled.div`
  padding-right: 20px;
  color: ${props => props.theme.palette.grey};
  cursor: pointer;
  font-weight: bold;

  &:hover {
    color: ${props => props.theme.palette.dark_grey};
  }
`

@inject('authStore')
@observer
class CommentItem extends Component {
  state = {
    isCommenting: false,
    shouldLoad: false
  }

  render() {
    const comment = this.props.comment

    const toggleCommenting = () => {
      this.setState(() => ({
        isCommenting: !this.state.isCommenting
      }))
    }

    const convertDateToString = dateString => {
      const date = new Date(dateString)
      return date
    }

    const hasCurrentUserLikedComment = (comment) => {
      return (comment.likedComments.filter(user => (user.id === this.props.authStore.id))).length > 0
    }

    return (
      <CommentContainer>
        <Title>
          <small>
            {comment.user.firstName} {comment.user.lastName} &#8226;{' '}
            <ReactTimeAgo
              date={convertDateToString(comment.createdAt)}
              locale="nl"
            />
          </small>
        </Title>

        <Container depth={this.props.depth}>
          <CommentText key={comment.id}>{comment.text}</CommentText>
          <BelowCommentText>
            <small>
              <BelowCommentTextItem data-testid={'comment_item_like_button'}>
                <HeartButton selected={hasCurrentUserLikedComment(comment)} commentId={comment.id} likes={comment.likedComments.length}/>
              </BelowCommentTextItem>
            </small>
            {this.props.depth < 4 && (
              <small onClick={() => toggleCommenting()}>
                <BelowCommentTextItem data-testid={'comment_item_comment_button'}>
                  <FontAwesomeIcon icon="comment" /> reageer
                </BelowCommentTextItem>
              </small>
            )}
          </BelowCommentText>
          {this.state.isCommenting && (
            <PostComment
              toggleCommenting={toggleCommenting}
              focusOnMount={true}
              comment={comment}
            />
          )}

          <Query
            query={HOW_MANY_COMMENTS_FOR_COMMENT_QUERY}
            variables={{commentId: comment.id}}>
            {({error, data}) => {
              if (error) console.log(error)

              return (
                <React.Fragment>
                  {this.props.depth < 4 &&
                    (this.state.shouldLoad ||
                      data.howManyCommentsForComment <
                        INITIAL_AMOUNT_OF_LOADED_COMMENTS) && (
                      <div>
                        <CommentSection
                          ideaId={this.props.ideaId}
                          depth={this.props.depth + 1}
                          commentId={comment.id}
                        />
                      </div>
                    )}

                  {!this.state.shouldLoad &&
                    data.howManyCommentsForComment >
                      INITIAL_AMOUNT_OF_LOADED_COMMENTS - 1 && (
                      <LoadMoreContainer
                        onClick={() =>
                          this.setState(() => ({shouldLoad: true}))
                        }>
                        <small>
                          {data.howManyCommentsForComment} antwoorden
                          bekijken&nbsp;
                        </small>
                        <FontAwesomeIcon icon="chevron-down" />
                      </LoadMoreContainer>
                    )}
                </React.Fragment>
              )
            }}
          </Query>
        </Container>
      </CommentContainer>
    )
  }
}

export default CommentItem
