import React, {Component} from 'react'
import Spinner from '../../shared/components/spinner'
import {Wrapper, SpinnerText} from './style/style'
import Verifier from './verificationHelper'

class Verification extends Component {
  render() {
    const id = this.props.match.params.id
    const token = this.props.match.params.token

    return (
      <Verifier
        id={id}
        token={token}
        render={({error, data}) => {
          if (error) return <Wrapper>Oeps, er ging iets mis!</Wrapper>
          if (data) {
            return (
              <Wrapper>
                Het e-mailadres {data.verify.email} is nu bevestigd.
              </Wrapper>
            ) 
          }
          return (
            <Wrapper>
              <SpinnerText>
                <Spinner /> Aan het verifiëren...
              </SpinnerText>
            </Wrapper>
          )
        }}
      />
    )
  }
}

export default Verification
