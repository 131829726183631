import React, {Component} from 'react'
import {Mutation, Query} from 'react-apollo'
import gql from 'graphql-tag'
import Tag from '../../shared/components/tag'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {observer, inject} from 'mobx-react'
import styled, {createGlobalStyle} from 'styled-components'
import Button from '../../shared/components/button'
import {PAGINATED_IDEAS_QUERY} from '../listing/ideaList'
import {IDEAS_PER_PAGE} from '../../constants'
import EditableTag from '../../shared/components/editableTag'

const POST_IDEA = gql`
  mutation PostIdea(
    $title: String!
    $description: String!
    $tagids: [String!]
  ) {
    postIdea(title: $title, description: $description, tagids: $tagids) {
      createdAt
      id
      title
      description
      accepted
      archived
      comments {
        id
        text
        commentId
      }
      likedIdeas {
        id
        firstName
        lastName
      }
      user {
        id
        firstName
        lastName
      }
      tags {
        id
        text
      }
    }
  }
`

const TAGS_QUERY = gql`
  query Tags($amount: Int!) {
      tags(amount: $amount) {
        id
        text
      }
    }
`

const ButtonSpace = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

const ButtonWithLeftMargin = styled(Button)`
  margin-left: 1em;
`

const QuillEditor = styled(ReactQuill)`
  background: white;
  border-radius: 4px;
  border: 1px solid #d1d1d1;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.07);
`

const TagPlace = styled.div`
  margin: 0.5em 0 0.5em 0;
`

const EditorStyleOverride = createGlobalStyle`
  .ql-editor {
    height: 50vh !important;
  }
  .ql-container {
    font-size: 16px !important;
  }
  .ql-toolbar.ql-snow {
      border: none !important;
      border-bottom: 1px solid #d1d1d1 !important;
  }
  .ql-container.ql-snow {
      border: none !important;
  }
`

@inject('ideaStore')
@observer
class PostIdea extends Component {
  state = {
    editableTags: []
  }

  refetch = null
  alreadyRefetched = false

  handleChange = value => {
    this.props.ideaStore.setIdeaDescription(value)
  }

  cancelPosting = () => {
    this.props.ideaStore.closeIdeaPost()
  }

  setTag = (tag) => {
    if (this.state.editableTags.includes(tag)) {
      this.state.editableTags.pop(tag)
    } else {
      this.state.editableTags.push(tag)
    }
  }

  render() {
    return (
      <div>
        <div>
          <TagPlace>
            <Query query={TAGS_QUERY} variables={{amount: 10}}>
              {({loading, error, data, refetch}) => {
                if (loading) return <div>Tags laden...</div>
                if (error) return <div>Kon tags niet laden :(</div>

                const tags = data.tags
                if (!this.alreadyRefetched) {
                  refetch()
                  this.alreadyRefetched = true
                }

                return (
                    <React.Fragment>
                      {tags.map(tag => (
                        <Tag key={tag.id} tag={tag} />
                      ))}
                    </React.Fragment>
                )
              }}
            </Query>
            <React.Fragment>
              {
                this.props.ideaStore.editableIdeaTags.map(tag => (
                  tag.added &&
                  <React.Fragment>
                    <EditableTag key={tag.id} id={tag.id} text={tag.text} added={tag.added} />
                  </React.Fragment>
                ))
              }
                <EditableTag />
              </React.Fragment>
          </TagPlace>
          <React.Fragment>
            <EditorStyleOverride />
            <QuillEditor
              value={this.props.ideaStore.ideaDescription}
              onChange={this.handleChange}
            />
          </React.Fragment>
          <ButtonSpace>
            <ButtonWithLeftMargin onClick={() => this.cancelPosting()}>
              Annuleren
            </ButtonWithLeftMargin>
            {this.props.ideaStore.allPostFieldsValid ? (
              <Mutation
                mutation={POST_IDEA}
                variables={{
                  title: this.props.ideaStore.ideaTitle,
                  description: this.props.ideaStore.ideaDescription,
                  tagids: this.props.ideaStore.ideaTags
                }}
                onCompleted={() =>
                  this.props.ideaStore.closeIdeaPost()
                }
                update={(store, { data: { postIdea } }) => {                  
                  const data = store.readQuery({ query: PAGINATED_IDEAS_QUERY, variables: {limit: IDEAS_PER_PAGE, cursor: null, searchQuery: ""}})
                  data.paginatedIdeas.edges.unshift(postIdea)
                  store.writeQuery({
                    query: PAGINATED_IDEAS_QUERY,
                    data
                  })
                }}
                >
                {postIdea => (
                  <ButtonWithLeftMargin green onClick={postIdea}>
                    Opslaan
                  </ButtonWithLeftMargin>
                )}
              </Mutation>
            ) : (
              <ButtonWithLeftMargin lightgreen disabled>
                Opslaan
              </ButtonWithLeftMargin>
            )}
          </ButtonSpace>
        </div>
      </div>
    )
  }
}

export default PostIdea
