import React, {PureComponent} from 'react'
import styled, {css} from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {ApolloConsumer} from 'react-apollo'
import gql from 'graphql-tag'

const ACCEPT_IDEA_MUTATION = gql`
  mutation AcceptIdeaMutation($ideaId: String!) {
    acceptIdea(ideaId: $ideaId)
  }
`

const AcceptContainer = styled(FontAwesomeIcon)`
  color: ${props => props.theme.palette.grey};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.palette.dark_grey};

    ${props =>
      props.disabled &&
      css`
        color: ${props => props.theme.palette.grey};
        cursor: not-allowed;
    `}
  }

  ${props =>
      props.selected &&
      css`
        color: ${props => props.theme.palette.dark_green};
  `}
`

class AcceptButton extends PureComponent {
  state = {
    selected: this.props.selected
  }

  render() {
    const handleClick = async(client) => {
      this.setState(() => ({
        selected: !this.state.selected
      }))

      // accept and deaccept idea
      const {data} = await client.mutate({
        mutation: ACCEPT_IDEA_MUTATION,
        variables: {ideaId: this.props.ideaId}
      }) 
    }

    return (
      <ApolloConsumer>
        {client => (
          <React.Fragment>
            {this.props.canSelect &&
              <AcceptContainer onClick={() => handleClick(client)} selected={this.props.selected} icon="check" />
            }
            {!this.props.canSelect &&
              <AcceptContainer disabled icon="check" />
            }
          </React.Fragment>
        )}
      </ApolloConsumer>
    )
  }
}

export default AcceptButton
