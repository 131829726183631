import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import gql from 'graphql-tag'
import {ApolloConsumer} from 'react-apollo'
import {EurekaTag, EurekaIcon} from './style/tag'
import './editableTag.css'

const ADD_TAG_MUTATION = gql`
  mutation AddTagMutation($text: String!) {
    addTag(text: $text) {
      id
      text
    }
  }
`

const UPDATE_TAG_MUTATION = gql`
  mutation UpdateTagMutation($id: String!, $text: String!) {
    updateTag(id: $id, text: $text) {
      id
      text
    }
  }
`

const REMOVE_TAG_MUTATION = gql`
  mutation RemoveTagMutation($id: String!) {
    removeTag(id: $id)
  }
`

@inject('ideaStore')
@observer
class EditableTag extends Component {
  state = {
    id: '',
    text: '',
    added: false
  }

  //editableTag can be initialised with values from props
  componentDidMount() {
    if (this.props.id && this.props.text && this.props.added != undefined) {
      this.setState(() => ({
        added: this.props.added,
        text: this.props.text,
        id: this.props.id
      }))
    }
  }

  render() {
    const deleteTag = async(client) => {
        await client.mutate({
          mutation: REMOVE_TAG_MUTATION,
          variables: {id: this.state.id}
        })
  
        this.setState(() => ({
          added: false
        }))

        this.props.ideaStore.modifyTag(this.state.id)
        this.props.ideaStore.removeEditableIdeaTag(this.state)
    }

    const onBlur = async(client) => {
      //not already added, add tag
      if (!this.state.added) {
        const editableTagNodes = document.querySelectorAll('[contenteditable]')
        // -2: quillEditor uses two contentEditables
        const text = editableTagNodes[editableTagNodes.length - 1 - 2].textContent

        if (text != '') {
          const { data } = await client.mutate({
            mutation: ADD_TAG_MUTATION,
            variables: {text: text}
          })
    
          this.setState(() => ({
            added: true,
            id: data.addTag.id,
            text: data.addTag.text
          }))
    
          this.props.ideaStore.modifyTag(this.state.id)
          this.props.ideaStore.addEditableIdeaTag(this.state)

          clearTag()
        }
      }
      
      //already added, -> update
      if (this.state.added) {
        const text = document.getElementById(this.state.id).textContent

        const { data } = await client.mutate({
          mutation: UPDATE_TAG_MUTATION,
          variables: {id: this.state.id, text: text}
        })
  
        this.setState(() => ({
          text: data.updateTag.text
        }))  
      }
    }

    const clearTag = () => {
      this.setState(() => ({
        text: '',
        id: '',
        added: false
      }))  
    }

    return (
      <EurekaTag selected={this.props.ideaStore.ideaTags.includes(this.state.id)} onClick={this.addTagToIdea}>
        <ApolloConsumer>
          {client => (
            <div>
              <span id={this.state.id} style={{cursor: 'text', whiteSpace: 'nowrap', overflow: 'hidden'}} contenteditable="true" placeholder="Nieuwe tag..." onBlur={() => onBlur(client)}>{this.state.text}</span>
              {this.state.added &&
                <EurekaIcon selected={true} onClick={() => deleteTag(client)}><FontAwesomeIcon icon="times" /></EurekaIcon>
              }
            </div>
          )}
        </ApolloConsumer>
      </EurekaTag>
    )
  }
}

export default EditableTag
