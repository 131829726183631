import React, {Component} from 'react'
import styled from 'styled-components'
import Logo from './logo'
import Menu from './menu'

const MenuHolder = styled.div`
    position: absolute;
    right: 0px;
    top: 20px;
`

class Header extends Component {
  render() {
    return (
      <React.Fragment>
        <Logo />
        <MenuHolder>
          <Menu />
        </MenuHolder>
      </React.Fragment>
    )
  }
}

export default Header
