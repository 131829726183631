import React, {PureComponent} from 'react'
import styled, {css} from 'styled-components'

const EurekaButton = styled.button`
    background: ${props => props.theme.palette.light_grey};
    border-radius: 3px;
    border: 2px solid ${props => props.theme.palette.light_grey};
    color: ${props => props.theme.palette.dark_grey};
    font-weight: bold;
    margin: 0.5em 0em;
    padding: 0.5em 1.5em;
    cursor: pointer;

    ${props =>
      props.green &&
      css`
        background: ${props => props.theme.palette.light_green};
        color: ${props => props.theme.palette.dark_green};
        border: 2px solid ${props => props.theme.palette.light_green};
      `}

    ${props =>
      props.lightgreen &&
      css`
        background: ${props => props.theme.palette.super_light_green};
        color: ${props => props.theme.palette.light_green};
        border: 2px solid ${props => props.theme.palette.super_light_green};
      `}

    ${props =>
      props.red &&
      css`
        background: ${props => props.theme.palette.light_red};
        color: ${props => props.theme.palette.dark_red};
        border: 2px solid ${props => props.theme.palette.light_red};
      `}

    ${props =>
      props.lightgrey &&
      css`
        background: ${props => props.theme.palette.super_light_grey};
        color: ${props => props.theme.palette.light_grey};
        border: 2px solid ${props => props.theme.palette.super_light_grey};
      `}

    ${props =>
      props.disabled &&
      css`
        cursor: auto;
    `}
`

class Button extends PureComponent {
  render() {
    return <EurekaButton {...this.props}>{this.props.children}</EurekaButton>
  }
}

export default Button
