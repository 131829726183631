import React, {Component} from 'react'
import DisplayTag from '../../shared/components/displayTag'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Redirect} from 'react-router-dom'
import {ClickableIdeaPart, IdeaHolder, IdeaHeader, IdeaText, IdeaFooter, IdeaFooterText, IdeaDescriptionSmall, IdeaActionGrouper} from '../style/style'
import ReactTimeAgo from 'react-time-ago'
import CommentCount from './commentCount'
import HeartButton from '../../shared/components/heartButton'
import {inject, observer} from 'mobx-react'
import AcceptButton from '../../shared/components/acceptButton'
import ArchiveButton from '../../shared/components/archiveButton'
import styled from 'styled-components'
import {withRouter} from 'react-router'

const Holder = styled.div`
  display: flex;
`

const TitleBackground = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

@inject('authStore')
@observer
class IdeaItem extends Component {
  state = {
    toIdea: false
  }

  render() {
    const convertDateToString = (dateString) => {
      const date = new Date(dateString)
      return date
    }

    const goToDetails = () => {
      this.props.history.push('/idea/' + this.props.idea.id)
    }

    const hasCurrentUserLikedIdea = () => {
      return (this.props.idea.likedIdeas.filter(user => (user.id === this.props.authStore.id))).length > 0
    }

    if (this.state.toIdea === true) {
      return <Redirect to={{pathname: '/idea/' + this.props.idea.id}}/>
    }

    return (
      <IdeaHolder selected={this.props.idea.accepted} archived={this.props.idea.archived}>
        <ClickableIdeaPart onClick={() => goToDetails()}>
          <IdeaHeader data-testid={'idea_item_header'}>
            {(!this.props.idea.accepted && !this.props.idea.archived) && <IdeaText><strong>{this.props.idea.title}</strong></IdeaText>}
            {this.props.idea.accepted && 
              <Holder>
                <TitleBackground>
                  <IdeaText><strong>{this.props.idea.title}</strong></IdeaText>
                  <IdeaText accepted><small><FontAwesomeIcon icon="check-circle" />&nbsp;geaccepteerd</small></IdeaText>
                </TitleBackground>
              </Holder>

          
            }
            {this.props.idea.archived && 
              <Holder>
                <TitleBackground>
                  <IdeaText><strong>{this.props.idea.title}</strong></IdeaText>
                  <IdeaText archived><small><FontAwesomeIcon icon="times-circle" />&nbsp; gearchiveerd</small></IdeaText>
                </TitleBackground>
              </Holder>
            }
            <IdeaText grey><small>
              <ReactTimeAgo date={convertDateToString(this.props.idea.createdAt)} locale="nl"/>
            </small></IdeaText>
          </IdeaHeader>
          
            <div>
              {this.props.idea.tags.map(tag => (
                <DisplayTag key={tag.id}>{tag.text}</DisplayTag>
              ))}
            </div>
            <IdeaDescriptionSmall data-testid={'idea_item_list_description'} dangerouslySetInnerHTML={{__html: this.props.idea.description}} />
          </ClickableIdeaPart>
        <IdeaFooter>
          <div>
            <IdeaText grey>&bull; {this.props.idea.user.firstName} {this.props.idea.user.lastName}</IdeaText>
          </div>
          <IdeaActionGrouper>
            {this.props.authStore.admin &&
              <React.Fragment>
                <IdeaFooterText data-testid={'idea_item_admin_accept_button'}>
                  <AcceptButton selected={this.props.idea.accepted} canSelect={!this.props.idea.archived} ideaId={this.props.idea.id}/>
                </IdeaFooterText>
                <IdeaFooterText data-testid={'idea_item_admin_archive_button'}>
                  <ArchiveButton selected={this.props.idea.archived} canSelect={!this.props.idea.accepted} ideaId={this.props.idea.id}/>
                </IdeaFooterText>
              </React.Fragment>
            }
            <IdeaFooterText data-testid={'idea_item_like_button'}>
              <HeartButton selected={hasCurrentUserLikedIdea()} ideaId={this.props.idea.id} likes={this.props.idea.likedIdeas.length}/>
            </IdeaFooterText>
            <IdeaFooterText>
              <FontAwesomeIcon onClick={() => goToDetails()} icon="comment" />
              {' '}
              <CommentCount ideaId={this.props.idea.id} />
            </IdeaFooterText>
          </IdeaActionGrouper>
        </IdeaFooter>
      </IdeaHolder>
    )
  }
}

export default withRouter(IdeaItem)
