import React, {PureComponent} from 'react'
import styled, {keyframes} from 'styled-components'
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'

library.add(faSpinner)

// Create the keyframes
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Spinning = styled.p`
  display: inline-block;
  animation: ${rotate} 1s linear infinite;
  margin: 0px;
  padding: 0px;
`

class Spinner extends PureComponent {
  render() {
    return (
      <Spinning {...this.props}>
        <FontAwesomeIcon icon="spinner" />
        {this.props.children}
      </Spinning>
    )
  }
}

export default Spinner
