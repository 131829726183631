import React, {Component} from 'react'
import styled from 'styled-components'
import LogoImage from '../../assets/eureka_logo.png'
import ElThunder from '../../assets/el_thunder.png'
import ElCircle from '../../assets/el_circle.png'
import ElDot from '../../assets/el_dot.png'
import ElTriangle from '../../assets/el_triangle.png'
import {withRouter} from 'react-router'

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
`

const ElThunderImg = styled.img`
    position: absolute; 
    left: 25vw; 
    top: 11vh; 
    @media (max-width: 900px) {
        visibility: hidden;
    }
`

const ElCircleImg = styled.img`
    position: absolute; 
    left: 35vw; 
    top: 5vh; 
    @media (max-width: 900px) {
        visibility: hidden;
    }
`

const ElDotImg = styled.img`
    position: absolute; 
    left: 60vw; 
    top: 5vh; 
    @media (max-width: 900px) {
        visibility: hidden;
    }
`

const ElTriangleImg = styled.img`
    position: absolute; 
    left: 70vw; 
    top: 12vh; 
    @media (max-width: 900px) {
        visibility: hidden;
    }
`

const EurekaLogo = styled.img`
    position:absolute;
    top: 12vh;
    height: 5em;
    cursor: pointer;
`

class Logo extends Component {
    render() {
      return (
          <React.Fragment>
            <ElThunderImg src={ElThunder} />
            <ElCircleImg src={ElCircle} />
            <ElDotImg src={ElDot} />
            <ElTriangleImg src={ElTriangle} />

            <Wrapper>
                <EurekaLogo src={LogoImage} onClick={() => this.props.history.push('/')} data-testid={'header_logo'} />
            </Wrapper>
          </React.Fragment>
      )
    }
}
export default withRouter(Logo)
