import React, {PureComponent} from 'react'
import styled, {css} from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {ApolloConsumer} from 'react-apollo'
import gql from 'graphql-tag'

const ARCHIVE_IDEA_MUTATION = gql`
  mutation ArchiveIdeaMutation($ideaId: String!) {
    archiveIdea(ideaId: $ideaId)
  }
`

const ArchiveContainer = styled(FontAwesomeIcon)`
  color: ${props => props.theme.palette.grey};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.palette.dark_grey};

    ${props =>
      props.disabled &&
      css`
        color: ${props => props.theme.palette.grey};
        cursor: not-allowed;
    `}
  }

  ${props =>
      props.selected &&
      css`
        color: ${props => props.theme.palette.dark_red};
  `}
`

class ArchiveButton extends PureComponent {
  state = {
    selected: this.props.selected
  }

  render() {
    const handleClick = async(client) => {
      this.setState(() => ({
        selected: !this.state.selected
      }))

      // archive and dearchive idea
      const { data } = await client.mutate({
        mutation: ARCHIVE_IDEA_MUTATION,
        variables: { ideaId: this.props.ideaId }
      }) 
    }

    return (
      <ApolloConsumer>
        {client => (
          <React.Fragment>
            {this.props.canSelect &&
              <ArchiveContainer onClick={() => handleClick(client)} selected={this.state.selected} icon="archive" />
            }
            {!this.props.canSelect &&
              <ArchiveContainer disabled icon="archive" />
            }
          </React.Fragment>
        )}
      </ApolloConsumer>
    )
  }
}

export default ArchiveButton
