import styled, {css} from 'styled-components'

export const EurekaTag = styled.div`
  background: ${props => props.theme.palette.light_grey};
  color: ${props => props.theme.palette.dark_grey};
  display: inline-block;
  margin: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 25px;
  cursor: pointer;

  ${props =>
      props.selected &&
      css`
        background: ${props => props.theme.palette.light_yellow};
        color: ${props => props.theme.palette.dark_yellow};
  `}
`

export const EurekaTagText = styled.p`
    margin: 0;
`

export const EurekaIcon = styled.p`
  opacity: 0.15;
  display: inline-block;
  margin: 0px;
  padding: 0px;

  ${props =>
      props.selected &&
      css`
        opacity: 1;
        padding-left: 0.5em;
  `}
`