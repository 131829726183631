import React, {Component} from 'react'
import {Mutation} from 'react-apollo'
import gql from 'graphql-tag'
import Button from '../../shared/components/button'
import Spinner from '../../shared/components/spinner'
import mascotte from '../../assets/mascotte_login.png'
import {
  Wrapper,
  StyledInput,
  AboveInput,
  AboveInputLabel,
  LoginMascotte,
  SpinnerText,
  BelowInput
} from './style/style'
import {inject, observer} from 'mobx-react'
import AuthNotification from '../../shared/components/authNotification'
import {Redirect, Link} from 'react-router-dom'

const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      firstName
      lastName
      token
      admin
    }
  }
`

@inject('authStore')
@observer
class Login extends Component {
  constructor(props) {
    super(props)
    this.loginButtonRef = React.createRef()
  }

  confirm = async data => {
    this.props.authStore.setLoggedIn(true)
    this.props.authStore.setId(data.login.id)
    this.props.authStore.setToken(data.login.token)
    this.props.authStore.setFirstName(data.login.firstName)
    this.props.authStore.setLastName(data.login.lastName)
    this.props.authStore.setAdmin(data.login.admin)
    this.props.history.push('/')
  }

  /* immediately login for testing purposes */
  componentWillMount() {
    /*this.props.authStore.setLoggedIn(true)
    this.props.authStore.setId('edf403ff-f8cb-40f0-9990-fb66eb2a6655')
    this.props.authStore.setFirstName('Daan')
    this.props.authStore.setLastName('Kennes')
    this.props.authStore.setAdmin(true)
    this.props.history.push('/')*/
  }

  keyPress = (e) => {
    if(e.keyCode === 13) {
       this.loginButtonRef.props.onClick()
    }
  }

  render() {
    if (!this.props.authStore.loggedIn) return (
      <div>
        <div>
          <Wrapper>
            <AboveInput>
              <LoginMascotte src={mascotte} />
              <AboveInputLabel>
                Welkom bij Eureka!&nbsp;
                <Link to={'/register'}>Klik hier om te registreren</Link>
              </AboveInputLabel>
            </AboveInput>

            <StyledInput
              value={this.props.authStore.email}
              onChange={e => this.props.authStore.setEmail(e.target.value)}
              type="text"
              placeholder="E-mailadres"
            />
            <br />
            <StyledInput
              value={this.props.authStore.password}
              onChange={e => this.props.authStore.setPassword(e.target.value)}
              onKeyDown={this.keyPress}
              type="password"
              placeholder="Wachtwoord"
            />
            <Mutation
              mutation={LOGIN_MUTATION}
              variables={{
                email: this.props.authStore.email,
                password: this.props.authStore.password
              }}
              onCompleted={data => this.confirm(data)}>
              {(mutation, {loading, error}) => (
                <React.Fragment>
                  <BelowInput>
                    <p>
                      <Link to={'/forgotpwd'}>
                        Ik ben mijn wachtwoord vergeten
                      </Link>
                    </p>

                    <div>
                      <Button ref={elem => this.loginButtonRef = elem} onClick={mutation}>
                        {loading && (
                          <SpinnerText>
                            <Spinner />
                          </SpinnerText>
                        )}
                        Inloggen
                      </Button>
                    </div>
                  </BelowInput>
                  <AuthNotification>
                    {error && 'Er ging iets mis!'}
                  </AuthNotification>
                </React.Fragment>
              )}
            </Mutation>
          </Wrapper>
        </div>
      </div>
    )
    if (this.props.authStore.loggedIn) return (
      <Redirect to={{pathname: '/'}} />
    )
  }
}

export default Login
