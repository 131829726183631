import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {EurekaTag, EurekaTagText, EurekaIcon} from './style/tag'

@inject('ideaStore')
@observer
class Tag extends Component {
  addTagToIdea = () => {
    this.props.ideaStore.modifyTag(this.props.tag.id)
  }

  render() {
    return (
      <EurekaTag selected={this.props.ideaStore.ideaTags.includes(this.props.tag.id)} onClick={this.addTagToIdea}>
        <EurekaTagText>{this.props.tag.text}
          <EurekaIcon selected={this.props.ideaStore.ideaTags.includes(this.props.tag.id)}>
            {this.props.ideaStore.ideaTags.includes(this.props.tag.id) && <FontAwesomeIcon icon="times" />}
          </EurekaIcon>
        </EurekaTagText>
      </EurekaTag>
    )
  }
}

export default Tag
