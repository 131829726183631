import React, {Component} from 'react'
import {Mutation} from 'react-apollo'
import gql from 'graphql-tag'
import Button from '../../shared/components/button'
import Spinner from '../../shared/components/spinner'
import mascotte from '../../assets/mascotte_login.png'
import {
  Wrapper,
  StyledInput,
  AboveInput,
  AboveInputLabel,
  LoginMascotte,
  SpinnerText,
  BelowInput
} from './style/style'
import {inject, observer} from 'mobx-react'
import AuthNotification from '../../shared/components/authNotification'
import {Redirect, Link} from 'react-router-dom'

const RESET_PASSWORD_WITHOUT_TOKEN_MUTATION = gql`
  mutation ResetPasswordWithoutTokenMutation($password: String!) {
    resetPasswordWithoutToken(password: $password)
  }
`

@inject('authStore')
@observer
class ResetPasswordWithoutToken extends Component {
  constructor(props) {
    super(props)
    this.resetButtonRef = React.createRef()
  }

  keyPress = (e) => {
    if(e.keyCode === 13) {
      if (this.resetButtonRef.props) this.resetButtonRef.props.onClick()
    }
  }

  render() {
    return (
      <div>
        <div>
          <Wrapper>
            <AboveInput>
              <LoginMascotte src={mascotte} />
              <AboveInputLabel>
                Kies een nieuw wachtwoord
              </AboveInputLabel>
            </AboveInput>

            <StyledInput
              value={this.props.authStore.password}
              onChange={e => this.props.authStore.setPassword(e.target.value)}
              type="password"
              placeholder="Wachtwoord"
            />
            <br />
            <StyledInput
              value={this.props.authStore.passwordVerification}
              onChange={e =>
                this.props.authStore.setPasswordVerification(e.target.value)
              }
              type="password"
              placeholder="Wachtwoord bevestigen"
              onKeyDown={this.keyPress}
            />
            
            <Mutation
              mutation={RESET_PASSWORD_WITHOUT_TOKEN_MUTATION}
              variables={{
                password: this.props.authStore.password
              }}
              onCompleted={() => this.props.authStore.setLoggedIn(false)}>
              {(mutation, {loading, error, data}) => (
                <React.Fragment>
                  <BelowInput>
                    <p>
                      <Link to={'/'}>
                        Homepagina
                      </Link>
                    </p>
                    <div>
                      {this.props.authStore.password && this.props.authStore.passwordsValid ? (
                        <Button ref={elem => this.resetButtonRef = elem} onClick={mutation}>
                        {loading && (
                          <SpinnerText>
                            <Spinner />
                          </SpinnerText>
                        )}
                        Wachtwoord instellen
                      </Button>
                      ) : (
                        <Button lightgrey disabled>
                          Wachtwoord instellen
                        </Button>
                      )}
                    </div>
                  </BelowInput>
                  <AuthNotification>
                    {error && 'Er ging iets mis!'}
                  </AuthNotification>
                  <AuthNotification>
                    {!this.props.authStore.passwordsValid &&
                      'De wachtwoorden komen niet overeen.'}
                  </AuthNotification>
                  {data &&
                    <Redirect to={{pathname: '/msg', state: {type: 'success', showHomePageLink: true, text: 'Je wachtwoord is opnieuw ingesteld.'}}} />
                  }
                </React.Fragment>
              )}
            </Mutation>
          </Wrapper>
        </div>
      </div>
    )
  }
}

export default ResetPasswordWithoutToken
