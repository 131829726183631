import React, {Component} from 'react'
import IdeaList from './listing/ideaList'
import PostIdea from './posting/postIdea'
import {inject, observer} from 'mobx-react'
import styled, {keyframes} from 'styled-components'
import {fadeOutLeft, fadeInRight} from 'react-animations'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {Mascotte, WideInput} from './style/style'
import mascNormal from '../assets/mascotte_normal.png'
import mascEyesLow from '../assets/mascotte_login.png'
import mascSearching from '../assets/mascotte_searching.png'
import FilterTab from '../shared/components/filterTab'
import Confetti from 'react-dom-confetti'

const config = {
  angle: "50",
  spread: 100,
  startVelocity: 45,
  elementCount: 70,
  dragFriction: 0.1,
  duration: 3000,
  delay: 0,
  width: "10px",
  height: "10px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
};

const fadeOutLeftAnimation = keyframes`${fadeOutLeft}`
const fadeInRightAnimation = keyframes`${fadeInRight}`

const enterTimeout = 500
const leaveTimeout = 300
const transitionName = 'fadetransition'

const ContainerIdeas = styled.section`
  padding: 0;
  margin: 0;

  &.${transitionName}-enter {
    opacity: .01;
  }

  &.${transitionName}-enter-active {
    animation: ${enterTimeout}ms ${fadeInRightAnimation};
  }
  
  &.${transitionName}-leave {
    opacity: 1;
	  transform: scaleX(1) translateY(0);
  }

  &.${transitionName}-leave-active {
    opacity: 01;
    animation: ${enterTimeout}ms ${fadeOutLeftAnimation};
  }
`

const TabHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (min-width: ${props => props.theme.sizes.mobile_size}) {
    justify-content: flex-end;
  }

  @media (max-width: ${props => props.theme.sizes.mobile_size}) {
    justify-content: center;
  }
`

const BelowInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const BelowInputLabel = styled.p`
  cursor: pointer;
  color: ${props => props.theme.palette.blue};

  &:hover{
    color: ${props => props.theme.palette.dark_grey};
  }
`

@inject('ideaStore')
@observer
class Home extends Component {
  state = {
    confetti: false
  }

  handleChange = (value) => {
    this.props.ideaStore.setIdeaDescription(value)
  }

  getCorrespondingMascotte = () => {
    if (this.props.ideaStore.viewMode === 'search') {
      return mascSearching
    }
    if (!this.props.ideaStore.viewMode === 'posting') {
      return mascNormal
    }
    return mascEyesLow
  }

  setViewMode = (viewMode) => {
    this.props.ideaStore.setViewMode(viewMode)
  }

  handleFilterChange = (checked) => {
    if (!checked) {
      this.props.ideaStore.setViewMode('mostRecent')
    } else {
      this.props.ideaStore.setViewMode('mostPopular')
    }
  }

  render() {
    const currentMascotte = this.getCorrespondingMascotte()

    const makeConfetti = () => {
      this.setState(() => ({
        confetti: true
      }))
      setTimeout(() => {
        this.setState(() => ({
          confetti: false
        }))
      }, 3000);
    }
    
    return (
      <div>
        <Mascotte src={currentMascotte} onClick={(e) => makeConfetti()} />
        <Confetti active={this.state.confetti} config={config}/>
        {this.props.ideaStore.viewMode !== 'search' &&
        <React.Fragment>
          <WideInput
            value={this.props.ideaStore.ideaTitle}
            onChange={e => this.props.ideaStore.setIdeaTitle(e.target.value)}
            type="text"
            placeholder="Titel"
            maxLength="80"
          />
          {this.props.ideaStore.viewMode !== 'posting' &&
          <BelowInput>
            <BelowInputLabel onClick={() => this.setViewMode('search')}>Zoek in ideeën</BelowInputLabel>
          </BelowInput>}
        </React.Fragment>
        }
        {this.props.ideaStore.viewMode === 'search' &&
        <React.Fragment>
          <WideInput
            value={this.props.ideaStore.searchQuery}
            onChange={e => this.props.ideaStore.setSearchQuery(e.target.value)}
            type="text"
            placeholder="Zoekterm"
          />
          {this.props.ideaStore.viewMode !== 'posting' &&
          <BelowInput>
            <BelowInputLabel onClick={() => this.setViewMode('mostRecent')}>Voeg een idee toe</BelowInputLabel>
          </BelowInput>
          }
        </React.Fragment>
        }

        <ReactCSSTransitionGroup
           transitionName={transitionName}
           transitionEnterTimeout={enterTimeout}
           transitionLeaveTimeout={leaveTimeout}>
             {this.props.ideaStore.viewMode !== 'posting' ? null : 
              <ContainerIdeas>
                  <PostIdea />
              </ContainerIdeas>}
        </ReactCSSTransitionGroup>

        <ReactCSSTransitionGroup
           transitionName={transitionName}
           transitionEnterTimeout={enterTimeout}
           transitionLeaveTimeout={leaveTimeout}>
             {this.props.ideaStore.viewMode === 'posting' ? null : 
              <ContainerIdeas>
                {this.props.ideaStore.viewMode === 'mostRecent' &&
                  <React.Fragment>

                    <TabHolder>
                      <FilterTab left selected>Nieuwste ideeën</FilterTab>
                      <FilterTab right onClick={() => this.setViewMode('mostPopular')}>Populairste ideeën</FilterTab>
                    </TabHolder>

                    <IdeaList viewMode={'mostRecent'}/>

                  </React.Fragment>
                }
                {this.props.ideaStore.viewMode === 'mostPopular' &&
                  <React.Fragment>

                    <TabHolder>
                      <FilterTab left onClick={() => this.setViewMode('mostRecent')}>Nieuwste ideeën</FilterTab>
                      <FilterTab right selected>Populairste ideeën</FilterTab>
                    </TabHolder>

                    <IdeaList viewMode={'mostPopular'}/>

                  </React.Fragment>
                }
                {this.props.ideaStore.viewMode === 'search' &&
                  <React.Fragment>

                    <TabHolder>
                      <FilterTab left onClick={() => this.setViewMode('mostRecent')}>Nieuwste ideeën</FilterTab>
                      <FilterTab right onClick={() => this.setViewMode('mostPopular')}>Populairste ideeën</FilterTab>
                    </TabHolder>

                    <IdeaList viewMode={'search'}/>

                  </React.Fragment>
                }
              </ContainerIdeas>}
        </ReactCSSTransitionGroup>

      </div>
    )
  }
}

export default Home
