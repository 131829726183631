import {observable, action, computed, autorun} from 'mobx'
import {AUTH_TOKEN} from '../../constants'

class AuthStore {
  @observable id = ''
  @observable firstName = ''
  @observable lastName = ''
  @observable email = localStorage.getItem('email') || ''
  @observable password = ''
  @observable passwordVerification = ''
  @observable token = ''
  @observable loggedIn = false
  @observable waitingForVerification = false
  @observable showMenu = false
  @observable admin = false

  @action setId(id) {
    this.id = id
  }
  
  @action setFirstName(fn) {
    this.firstName = fn
  }

  @action setLastName(ln) {
    this.lastName = ln
  }

  @action setEmail(email) {
    this.email = email
  }

  @action setPassword(password) {
    this.password = password
  }

  @action setPasswordVerification(passwordVerification) {
    this.passwordVerification = passwordVerification
  }

  @action setLoggedIn(loggedIn) {
    this.loggedIn = loggedIn
  }

  @action setToken(token) {
    this.token = token
    localStorage.setItem(AUTH_TOKEN, token)
  }

  @action setWaitingForVerification(waitingForVerification) {
    this.waitingForVerification = waitingForVerification
  }

  @action setShowMenu(showMenu) {
    this.showMenu = showMenu
  }

  @action setAdmin(admin) {
    this.admin = admin
  }

  @computed get passwordsValid() {
    return this.password === this.passwordVerification
  }

  @computed get allRegisterFieldsComplete() {
    return (
      this.firstName &&
      this.lastName &&
      this.email &&
      this.password &&
      this.passwordVerification
    )
  }

  autorunLogout = autorun(() => {
    if (!this.loggedIn) {
      this.firstName = ''
      this.lastName = ''
      this.password = ''
      this.passwordVerification = ''
      this.showMenu = false
      this.admin = false
    }
  })

  autorunLogin = autorun(() => {
    if (this.loggedIn) {
     this.showMenu = true
     this.password = ''
     this.passwordVerification = ''
     localStorage.setItem('email', this.email)
    }
  })

  autorunEmailVerification = autorun(() => {
    if (this.waitingForVerification) {
      this.email = ''
      this.firstName = ''
      this.lastName = ''
      this.password = ''
      this.passwordVerification = ''
    }
  })
}

export default new AuthStore()
