export const theme = {
  palette: {
    background: '#F9FDFF',
    light_green: '#CCEBE3',
    dark_green: '#0c7356',
    super_light_green: '#EAF7F3',
    light_red: '#FFD6E2',
    dark_red: '#E80246',
    light_yellow: '#F8F2BD',
    dark_yellow: '#A58602',
    grey: '#BFBFBF',
    light_grey: '#E5E5E5',
    super_light_grey: '#f4f4f4',
    dark_grey: '#5C5C5C',
    blue: '#6f6dc9',
    dark_blue: '#100be7',
    pink: '#D32651',
    dark_pink: '#a00b30'
  },
  sizes: {
    mobile_size: '481px'
  },
}
