import React, {Component} from 'react'
import CommentSection from './commentSection'
import styled from 'styled-components'
import PostComment from './postComment'

const Container = styled.div`
  margin: 10px 0 20px 0;
  padding: 5px 20px 5px 10px;
  background: white;
  border-bottom: 1px solid ${props => props.theme.palette.light_grey};
`

class CommentContainer extends Component {
  render() {
    return (
      <Container>
        <PostComment
          toggleCommenting={() => void 0}
          focusOnMount={false}
          comment={{commentId: null, ideaId: this.props.ideaId}}
        />
        <CommentSection ideaId={this.props.ideaId} depth={0} />
      </Container>
    )
  }
}

export default CommentContainer
