import React, {PureComponent} from 'react'
import styled from 'styled-components'

const EurekaInput = styled.input`
  background: #fff;
  color: #525865;
  border-radius: 4px;
  border: 1px solid #d1d1d1;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.07);
  font-family: inherit;
  font-size: 1em;
  line-height: 1.45;
  outline: none;
  padding: 0.6em 1.45em 0.7em;
`

class Input extends PureComponent {
  render() {
    return <EurekaInput {...this.props}>{this.props.children}</EurekaInput>
  }
}

export default Input
